import {createReducer} from "redux-act";
import {IHelpReducer} from "modules/types/reducers";
import * as actions from "modules/actions";

const initialState: IHelpReducer = {
	help_pages: {
		guidelines: {
			title: "",
			text: "",
		},
		prizes: {
			title: "",
			text: "",
		},
		terms: {
			title: "",
			text: "",
		},
	},
	faqList: [],
	isFetched: false
};

export const help = createReducer<IHelpReducer>({}, initialState);
help.on(actions.fetchFaqsSuccess, (state, payload) => ({
	...state,
	faqList: payload,
}));
help.on(actions.fetchHelpsSuccess, (state, payload) => ({
	...state,
	help_pages: {...payload},
	isFetched: true
}));
