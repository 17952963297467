import {createReducer} from "redux-act";
import {IRidersReducer} from "modules/types/riders";
import {fetchRiders, fetchRidersFailed, fetchRidersSuccess} from "modules/actions";
import {RequestState} from "modules/constants/enums";

const initialState: IRidersReducer = {
	list: [],
	/**
	 * Put "Requested" instead of "IDLE" state by default because riders are requesting at the app start,
	 * and not by action.
	 */
	requestState: RequestState.Requested,
};

export const riders = createReducer<IRidersReducer>({}, initialState)
	.on(fetchRiders, (state) => ({
		...state,
		requestState: RequestState.Requested,
	}))
	.on(fetchRidersSuccess, (state, list) => ({
		list,
		requestState: RequestState.Received,
	}))
	.on(fetchRidersFailed, (state) => ({
		...state,
		requestState: RequestState.Requested,
	}));
