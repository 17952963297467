import React from "react";
import {Redirect, Route, RouteProps} from "react-router-dom";
import {useSelector} from "react-redux";
import {getIsUserAuthed} from "modules/selectors/user";

export const PrivateRoute: React.FC<RouteProps> = (props) => {
	const isAuthed = useSelector(getIsUserAuthed);
	window.scrollTo(0, 0);
	return isAuthed ? <Route {...props} /> : <Redirect to={"/"} />;
};

export const NotAuthOnlyRoute: React.FC<RouteProps> = (props) => {
	const isAuthed = useSelector(getIsUserAuthed);
	window.scrollTo(0, 0);
	return isAuthed ? <Redirect to={"/team"} /> : <Route {...props} />;
};
