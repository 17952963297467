import React, {useEffect, useRef} from "react";
import {motion} from "framer-motion";
import styled from "styled-components";
import {useDimensions} from "modules/hooks/global";
import {MobileMenu} from "components/Menu/MobileMenu";
import {useLocation} from "react-router";
import {media} from "assets/css/media";
import {toggleBodyClass} from "modules/utils/helpers/dom";
import {useMedia} from "react-use";

const Wrapper = styled(motion.nav)`
	z-index: 3;
	margin-left: auto;

	.background {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		width: 300px;
		background: #603f99c7;
		z-index: 10;
		box-shadow: inset 0 0 40px -2px rgb(255 255 255 / 30%);
		@media (max-width: ${media.desktop}) {
			width: 100%;
		}
	}
`;

const sidebar = {
	open: (height: number) => {
		const webTransition = {
			type: "spring",
			stiffness: 15,
			restDelta: 2,
		};
		return {
			zIndex: 1,
			clipPath: `circle(${height * 2 + 200}px at 86% 48px)`,
			WebkitClipPath: `circle(${height * 2 + 200}px at 86% 48px)`,
			transition: webTransition,
		};
	},
	closed: {
		zIndex: 0,
		clipPath: "circle(0px at 86% 48px)",
		WebkitClipPath: "circle(0px at 86% 48px)",
		transition: {
			delay: 0.5,
			type: "spring",
			stiffness: 400,
			damping: 40,
		},
	},
};

interface IProps {
	isOpen: boolean;
	toggleCallback: () => void;
}

export const MobileMenuAnimated: React.FC<IProps> = ({isOpen, toggleCallback}) => {
	const {pathname} = useLocation();
	const isDesktop = useMedia(`(min-width: ${media.mobile})`);

	const containerRef = useRef(document.createElement("div"));
	const {height} = useDimensions(containerRef);

	const windowHeight = window.innerHeight;
	useEffect(() => {
		toggleBodyClass("fixed-scroll", isOpen);
	}, [isOpen]);

	useEffect(() => {
		if (isOpen) {
			toggleCallback();
		}
		// eslint-disable-next-line
	}, [pathname]);

	return !isDesktop ? (
		<Wrapper
			initial={false}
			animate={isOpen ? "open" : "closed"}
			custom={height}
			ref={containerRef}
			layout
			inherit={false}>
			<motion.div
				className={"background"}
				transformTemplate={() => `translateX(0) translateY(0) translateZ(0)`}
				variants={sidebar}
				custom={windowHeight}
			/>
			<MobileMenu isOpen={isOpen} />
		</Wrapper>
	) : null;
};
