import {IDictionary, IFormState} from "modules/types";

export const LOGIN__DEFAULT_FORM_STATE: IDictionary<IFormState> = {
	email: {
		value: "",
		error: "",
		validations: "required, emailFormat",
	},
	password: {
		value: "",
		error: "",
		validations: "required, min8",
	},
};

export const FORGOT_PASS_DEFAULT_FORM_STATE: IDictionary<IFormState> = {
	email: {
		value: "",
		error: "",
		validations: "required, emailFormat",
	},
};

export const RESET_PASS_DEFAULT_FORM_STATE: IDictionary<IFormState> = {
	password: {
		value: "",
		error: "",
		validations: "required, min8",
		errorsTexts: {
			matchEmail: "register_form_password_error",
		},
	},
	confirmPassword: {
		value: "",
		error: "",
		validations: "required, min8, matchPassword",
		errorsTexts: {
			matchPassword: "register_form_password_confirmation_error",
		},
	},
};

const validation1 = "required, max40";

export const REGISTER__DEFAULT_FORM_STATE: IDictionary<IFormState> = {
	firstName: {
		value: "",
		error: "",
		validations: validation1,
		errorsTexts: {
			required: "register_form_first_name_error",
		},
	},
	lastName: {
		value: "",
		error: "",
		validations: validation1,
		errorsTexts: {
			required: "register_form_first_name_error",
		},
	},
	email: {
		value: "",
		error: "",
		validations: "required, emailFormat",
		errorsTexts: {
			required: "register_form_email_error",
		},
	},
	confirmEmail: {
		value: "",
		error: "",
		validations: "required, emailFormat, matchEmail",
		errorsTexts: {
			matchEmail: "register_form_email_confirmation_error",
		},
	},
	password: {
		value: "",
		error: "",
		validations: "required, min8",
		errorsTexts: {
			matchEmail: "register_form_password_error",
		},
	},
	confirmPassword: {
		value: "",
		error: "",
		validations: "required, min8, matchPassword",
		errorsTexts: {
			matchPassword: "register_form_password_confirmation_error",
		},
	},
	birthday: {
		value: "",
		error: "",
		validations: "required",
		errorsTexts: {
			required: "register_form_dob_error",
		},
	},
	country: {
		value: "",
		error: "",
		validations: "required",
		errorsTexts: {
			required: "register_form_dob_error",
		},
	},
	username: {
		value: "",
		error: "",
		validations: "required, max40",
		errorsTexts: {
			required: "registration_form_team_name_error",
		},
	},
	heardAboutGameFrom: {
		value: "",
		error: "",
	},
	sponsorOptIn: {
		value: "false",
		error: "",
	},
	newsletter: {
		value: "false",
		error: "",
	},
	favoriteSquadId: {
		value: "",
		error: "",
		validations: "required",
		errorsTexts: {
			required: "registration_form_favourite_team_error",
		},
	},
	favoriteRider: {
		value: "",
		error: "",
	},
	favoriteRaceId: {
		value: "",
		error: "",
	},
	terms: {
		value: "",
		error: "",
		validations: "required",
	},
};
