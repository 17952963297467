export const toggleBodyClass = (className: string, state: boolean) => {
	if (state) {
		document.body.classList.add(className);
	} else {
		document.body.classList.remove(className);
	}
};

export const routeLayoutsAnimations = {
	initial: {
		opacity: 0,
	},
	in: {
		opacity: 1,
	},
	out: {
		opacity: 0,
	},
};
