import {call, put} from "typed-redux-saga";
import {Api} from "modules/utils/Api";
import {fetchRacesSuccess} from "modules/actions/races";

export const fetchRacesSaga = function* () {
	try {
		const races = yield* call(Api.JSON.races);
		yield put(fetchRacesSuccess(races));
	} catch (e) {
		console.log(e);
	}
};
