import styled from "styled-components";

export const FormField = styled.div`
	margin-bottom: 13px;
	position: relative;
	&.error {
		label,
		input,
		select,
		textarea {
			border-color: #f83f2d;
			color: #f83f2d;
		}
	}
`;
