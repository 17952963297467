import styled from "styled-components";
import {media} from "assets/css/media";
import {motion} from "framer-motion";

interface IBaseContainer {
	align?: string;
	justify?: string;
	direction?: string;
	overFlow?: string;
}

export const BaseContainer = styled(motion.div)<IBaseContainer>`
	width: 100%;
	max-width: 1180px;
	padding: 0 20px;
	margin: 0 auto;
	display: flex;
	align-items: ${(props) => props.align || "flex-start"};
	justify-content: ${(props) => props.justify || "flex-start"};
	flex-direction: ${(props) => props.direction || "row"};
	overflow: ${(props) => props.overFlow || "initial"};
	@media (max-width: ${media.mobile}) {
		padding: 0 10px;
	}
`;
