import {createAction} from "redux-act";
import {
	ICreateLeaguePayload,
	IInviteEmails,
	IJoinFilter,
	IJoinToLeagueRequest,
	ILeague,
	ILeagueId,
	ILeaveLeagueAction,
	IUpdateLeaguePayload,
	TLeagueCurrentResponse,
	TLeaguesShowMyResponse,
} from "modules/types/leagues";

export const fetchMyLeagues = createAction("fetchMyLeagues");
export const fetchMyLeaguesSuccess = createAction<TLeaguesShowMyResponse>("fetchMyLeaguesSuccess");
export const fetchMyLeaguesError = createAction("fetchMyLeaguesError");

export const fetchLeaguesForJoin = createAction<IJoinFilter>("fetchLeaguesForJoin");
export const fetchLeaguesForJoinSuccess = createAction<TLeaguesShowMyResponse>("fetchLeaguesForJoinSuccess");
export const fetchLeaguesForJoinError = createAction("fetchLeaguesForJoinError");

export const clearLeaguesList = createAction("clearLeaguesList");

export const joinToLeague = createAction<IJoinToLeagueRequest>("joinToLeague");
export const searchAndJoin = createAction<IJoinToLeagueRequest>();
export const joinToLeagueSuccess = createAction<string>("joinToLeagueSuccess");
export const rollBackJoinLeague = createAction<string>("rollBackJoinLeague");

export const fetchThisLeague = createAction<ILeagueId>("fetchThisLeague");
export const fetchThisLeagueSuccess = createAction<TLeagueCurrentResponse>("fetchThisLeagueSuccess");
export const fetchThisLeagueError = createAction("fetchThisLeagueError");

export const clearLeague = createAction("clearLeague");

export const postCreateLeague = createAction<ICreateLeaguePayload>("postCreateLeague");
export const postCreateLeagueSuccess = createAction<ILeague>("postCreateLeagueSuccess");
export const postCreateLeagueError = createAction();

export const postUpdateLeague = createAction<IUpdateLeaguePayload>("postUpdateLeague");
export const postUpdateLeagueSuccess = createAction<ILeague>("postUpdateLeagueSuccess");
export const postUpdateLeagueError = createAction("postUpdateLeagueError");
export const postUpdateLeagueReset = createAction("postUpdateLeagueReset");

export const postInvite = createAction<IInviteEmails>("postInvite");
export const postInviteSuccess = createAction("postInviteSuccess");
export const postInviteError = createAction("postInviteError");
export const postInviteReset = createAction("postInviteReset");

export const removeLeague = createAction<ILeagueId>("removeLeague");
export const leaveLeague = createAction<ILeaveLeagueAction>("leaveLeague");
export const removeOrLeaveLeagueSuccess = createAction<boolean>("removeOrLeaveLeagueSuccess");
