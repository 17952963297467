import React, {useState} from "react";
import styled from "styled-components";
import i18n from "i18next";
import {getEnvVariable} from "modules/utils/helpers";
import {IDictionary} from "modules/types";
import chevron from "assets/img/icons/arrow-down.svg";
import {Exist} from "components/Exist";
import {media} from "assets/css/media";
import {partial} from "lodash";

const Wrapper = styled.div`
	position: relative;
	cursor: pointer;
	@media (max-width: ${media.mobile}) {
		display: none;
	}
`;
const Toggler = styled.div`
	display: flex;
	align-items: center;
	text-transform: uppercase;
`;
const Dropdown = styled(Toggler)`
	background: #fff;
	position: absolute;
	top: 102%;
	width: 100%;
	padding: 5px;
`;
const Flag = styled.img<{isShow?: boolean}>`
	width: 20px;
	height: 20px;
	margin-right: 10px;
	border-radius: 50%;
	border: 2px solid #ddd;
	object-fit: cover;
	display: ${(props) => (props.isShow ? "block" : "none")};
`;
const Chevron = styled.img`
	width: 10px;
	height: 10px;
	margin-left: 10px;
`;

const getCountryImage = (countryKey: string) =>
	countryKey ? `${getEnvVariable("REACT_APP_MEDIA")}flags/${countryKey}.png?t=${Date.now()}` : "";

const flags: IDictionary<string> = {
	en: "gb",
	it: "it",
};

const lngAbr: IDictionary<string> = {
	en: "ENG",
	it: "ITA",
};

const useLanguageSwitcher = (openState?: boolean, toggleCallback?: (state: boolean) => void) => {
	const currentLang = i18n.language;
	const countryFlagLink = getCountryImage(flags[currentLang]);
	const notSelectedLang = i18n.language === "en" ? "it" : "en";
	const notSelectedFlagLink = getCountryImage(flags[notSelectedLang]);
	const [selectedLang, setSelectedLanguage] = useState(currentLang);
	const toggleHandler = () => {
		if (toggleCallback) {
			toggleCallback(!openState);
		}
	};
	const setLanguageHandler = () => {
		void i18n.changeLanguage(notSelectedLang);
		setSelectedLanguage(notSelectedLang);
		if (toggleCallback) {
			toggleCallback(!openState);
		}
	};
	const setLanguage = (language: string = notSelectedLang) => {
		void i18n.changeLanguage(language);
		setSelectedLanguage(language);
	};
	return {
		selectedLang,
		countryFlagLink,
		notSelectedLang,
		notSelectedFlagLink,
		toggleHandler,
		setLanguageHandler,
		setLanguage,
	};
};

export const LanguageSwitcher: React.FC = () => {
	const [isOpen, setIsOpen] = useState(false);
	const {selectedLang, notSelectedLang, notSelectedFlagLink, toggleHandler, setLanguageHandler} = useLanguageSwitcher(
		isOpen,
		setIsOpen
	);

	return (
		<Wrapper>
			<Toggler onClick={toggleHandler}>
				<Flag src={getCountryImage("gb")} isShow={selectedLang === "en"} />{" "}
				<Flag src={getCountryImage("it")} isShow={selectedLang === "it"} /> {lngAbr[selectedLang]}{" "}
				<Chevron src={chevron} />
			</Toggler>
			<Exist when={isOpen}>
				<Dropdown onClick={setLanguageHandler}>
					<Flag src={notSelectedFlagLink} isShow={true} /> {lngAbr[notSelectedLang]}
				</Dropdown>
			</Exist>
		</Wrapper>
	);
};

const MobileWrapper = styled.div``;
const MobileFlag = styled(Flag)<{isSelected: boolean}>`
	width: 40px;
	height: 40px;
	margin-right: 20px;
	opacity: ${(props) => (props.isSelected ? 1 : 0.5)};
`;

export const MobileLanguageSwitcher: React.FC = () => {
	const {selectedLang, setLanguage} = useLanguageSwitcher();
	return (
		<MobileWrapper>
			<MobileFlag
				src={getCountryImage("gb")}
				isSelected={selectedLang === "en"}
				onClick={partial(setLanguage, "en")}
			/>
			<MobileFlag
				src={getCountryImage("it")}
				isSelected={selectedLang === "it"}
				onClick={partial(setLanguage, "it")}
			/>
		</MobileWrapper>
	);
};
