// Next polyfills are required to made IE11 work.
import "core-js/es";
import "core-js/features/url";
import "core-js/features/url-search-params";

import "assets/css/reset.css";
import "assets/css/fonts.css";
import "assets/css/core.css";

import React, {ComponentType, lazy, Suspense} from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router, Route, RouteComponentProps, Switch} from "react-router-dom";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {Provider} from "react-redux";
import {store} from "modules/store";
import {NotAuthOnlyRoute, PrivateRoute} from "components/Routes";
import {AnimatePresence} from "framer-motion";
import {Header} from "components/Header";
import {HOCi18n} from "components/HOCi18n";
import {Footer} from "components/Footer";
import {NotFound} from "pages/NotFound";
import {AdvertRectangle} from "components/Advert";
import {Preloader} from "components/Preloader";
import {GlobalPreLoader} from "components/GlobalPreLoader";
import GlobalErrors from "components/GlobalErrors";
import {RedirectToJoinLeagues} from "components/League/LeaguesJoinList/RadirectToJoinLeagues";
import {ResetPasswordModal} from "components/ResetPasswordModal";
import {ResetPassConfirmModal} from "components/ResetPasswordModal/ResetPassConfirmModal";
import {IS_ADVERTISE_HIDDEN, IS_PRIZES_HIDDEN} from "./modules/constants";

type Factory<T> = () => Promise<{default: ComponentType<T>}>;

export function retryFailLoad<T>(fn: Factory<T>, retriesLeft = 5, interval = 1000): Factory<T> {
	return () =>
		new Promise((resolve, reject) => {
			fn()
				.then(resolve)
				.catch((error: unknown) => {
					setTimeout(() => {
						if (retriesLeft === 1) {
							window.location.reload();
							reject(error);
							return;
						}
						retryFailLoad(fn, retriesLeft - 1, interval)().then(resolve, reject);
					}, interval);
				});
		});
}

const LoginPage = lazy(retryFailLoad(() => import("./pages/LoginPage")));
const Registration = lazy(retryFailLoad(() => import("./pages/Registration")));
const Account = lazy(retryFailLoad(() => import("./pages/Account")));
const Leagues = lazy(retryFailLoad(() => import("./pages/Leagues")));
const LeaguesJoin = lazy(retryFailLoad(() => import("pages/leagues/LeaguesJoin")));
const LeagueCreate = lazy(retryFailLoad(() => import("pages/leagues/LeagueCreate")));
const LeagueAbout = lazy(retryFailLoad(() => import("pages/leagues/LeagueAbout")));
const LeagueInvites = lazy(retryFailLoad(() => import("pages/leagues/LeagueInvites")));
const LeagueSettings = lazy(retryFailLoad(() => import("pages/leagues/LeagueSettings")));
const LeagueRankings = lazy(retryFailLoad(() => import("pages/leagues/LeagueRankings")));
const Team = lazy(retryFailLoad(() => import("./pages/Team")));
const Help = lazy(retryFailLoad(() => import("pages/Help")));
const ContactSection = lazy(retryFailLoad(() => import("pages/help/ContactSection")));
const HowToPlaySection = lazy(retryFailLoad(() => import("pages/help/HowToPlaySection")));
const FaqsSection = lazy(retryFailLoad(() => import("pages/help/FaqsSection")));
const RulesSection = lazy(retryFailLoad(() => import("pages/help/RulesSection")));
const ScoringHelpSection = lazy(retryFailLoad(() => import("pages/help/ScoringSection")));
const Prizes = lazy(retryFailLoad(() => import("./pages/Prizes")));
const ResetPassword = lazy(retryFailLoad(() => import("./pages/ResetPassword")));
const Rankings = lazy(retryFailLoad(() => import("./pages/Rankings")));

Sentry.init({
	dsn: "https://2b0cd1ffe5bc40a58e6208efae9526fc@o151969.ingest.sentry.io/5693952",
	integrations: [
		new Integrations.BrowserTracing(),
		new Sentry.Integrations.Breadcrumbs({
			console: false,
		}),
	],
	environment: process.env.REACT_APP_SENTRY_ENV || "development",
	allowUrls: [".fanhubmedia.com", "fantasygame.velon.cc"],
	denyUrls: [
		"quantcast",
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i,
	],
	sampleRate: 0.1,
});

const App: React.FC = () => (
	<Provider store={store}>
		<HOCi18n>
			<Router basename={"/"}>
				<Header />
				{IS_ADVERTISE_HIDDEN ? null : <AdvertRectangle id="banner-top" />}
				<Route
					render={({location}: RouteComponentProps) => (
						<Suspense fallback={<Preloader withCoverBg={true} position={"center"} />}>
							<AnimatePresence exitBeforeEnter={true} initial={false}>
								<RedirectToJoinLeagues />
								<Switch location={location} key={location.pathname}>
									<NotAuthOnlyRoute exact={true} path={"/"} component={LoginPage} />
									<NotAuthOnlyRoute exact={true} path={"/sign-up"} component={Registration} />
									<PrivateRoute exact={true} path={"/account"} component={Account} />
									<PrivateRoute exact={true} path={"/team"} component={Team} />
									<PrivateRoute exact={true} path="/leagues" component={Leagues} />
									<PrivateRoute exact={true} path="/leagues/join" component={LeaguesJoin} />
									<Route exact={true} path={"/leagues/join/:code?"} component={LeaguesJoin} />
									<PrivateRoute exact={true} path={"/leagues/create/:id?"} component={LeagueCreate} />
									<PrivateRoute exact={true} path="/leagues/:id/about" component={LeagueAbout} />
									<PrivateRoute exact={true} path="/leagues/:id/invites" component={LeagueInvites} />
									<PrivateRoute
										exact={true}
										path="/leagues/:id/settings"
										component={LeagueSettings}
									/>
									<PrivateRoute
										exact={true}
										path="/leagues/:id/rankings"
										component={LeagueRankings}
									/>
									<Route exact={true} path={"/rankings"} component={Rankings} />
									<Route exact={true} path={"/reset-password"} component={ResetPassword} />
									<Route exact={true} path="/help/" component={Help} />
									<Route exact={true} path="/help/contact" component={ContactSection} />
									<Route exact={true} path="/help/how-to-play" component={HowToPlaySection} />
									<Route exact={true} path="/help/rules" component={RulesSection} />
									<Route exact={true} path="/help/faqs" component={FaqsSection} />
									<Route exact={true} path="/help/scoring" component={ScoringHelpSection} />
									{IS_PRIZES_HIDDEN ? null : <Route exact={true} path="/prizes" component={Prizes} />}
									<Route path={"*"} component={NotFound} />
								</Switch>
							</AnimatePresence>
						</Suspense>
					)}
				/>
				{IS_ADVERTISE_HIDDEN ? null : <AdvertRectangle id="banner-bottom" />}
				<Footer />
				<GlobalErrors />
				<ResetPasswordModal />
				<ResetPassConfirmModal />
			</Router>
		</HOCi18n>
		<GlobalPreLoader />
	</Provider>
);
ReactDOM.render(<App />, document.getElementById("root"));
