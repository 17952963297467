import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {getIsUserAuthed} from "modules/selectors/user";

export const RedirectToJoinLeagues: React.FC = () => {
	const codeFromLocalStore = localStorage.getItem("codeForJoin") || "";
	const isAuth = useSelector(getIsUserAuthed);
	const history = useHistory();
	useEffect(() => {
		if (codeFromLocalStore && isAuth) {
			history.push(`/leagues/join/${codeFromLocalStore}`);
		}
	}, [codeFromLocalStore, isAuth, history]);

	return null;
};
