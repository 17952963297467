import {IDictionary} from "modules/types";

export const MARQUEE = "myteam_line_up_marquee";
export const SPRINTERS = "myteam_line_up_sprinters";
export const CLIMBERS = "myteam_line_up_climbers";

export const MARQUEE_A = "myteam_filter_roadcodeleadersa_filed";
export const MARQUEE_B = "myteam_filter_roadcodeleadersb_filed";
export const SPRINTER_A = "myteam_filter_sprintersa_filed";
export const SPRINTER_B = "myteam_filter_sprintersb_filed";
export const CLIMBERS_A = "myteam_filter_climbersa_filed";
export const CLIMBERS_B = "myteam_filter_climbersb_filed";
export const ALL_ROUNDERS = "myteam_line_up_all_rounders";

export const LINEUP_POSITIONS: IDictionary<string> = {
	0: MARQUEE_A,
	1: MARQUEE_B,
	2: SPRINTER_A,
	3: SPRINTER_B,
	4: CLIMBERS_A,
	5: CLIMBERS_B,
	6: ALL_ROUNDERS,
	7: ALL_ROUNDERS,
};

export const LINEUP_POSITIONS_EMPTY_CARD: IDictionary<string> = {
	0: "myteam_line_up_card1",
	1: "myteam_line_up_card2",
	2: "myteam_line_up_card3",
	3: "myteam_line_up_card4",
	4: "myteam_line_up_card5",
	5: "myteam_line_up_card6",
	6: "myteam_line_up_card7",
	7: "myteam_line_up_card8",
};

export const LINEUP_TITLE_POSITIONS: IDictionary<string> = {
	0: MARQUEE,
	1: "",
	2: SPRINTERS,
	3: "",
	4: CLIMBERS,
	5: "",
	6: ALL_ROUNDERS,
	7: "",
};

export const LINEUP_TITLE_POSITIONS_TOOLTIP: IDictionary<string> = {
	0: "myteam_line_up_marquee_tooltips",
	1: "",
	2: "myteam_line_up_sprinters_tooltips",
	3: "",
	4: "myteam_line_up_climbers_tooltips",
	5: "",
	6: "myteam_line_up_all_rounders_tooltips",
	7: "",
};

export const RIDERS_POSITIONS_MAP: IDictionary<string> = {
	marquee_a: MARQUEE_A,
	marquee_b: MARQUEE_B,
	sprinter_a: SPRINTER_A,
	sprinter_b: SPRINTER_B,
	climber_a: CLIMBERS_A,
	climber_b: CLIMBERS_B,
	all_rounder: ALL_ROUNDERS,
};

export const LINEUP_POSITION_KEYS = [
	"marquee_a",
	"marquee_b",
	"sprinter_a",
	"sprinter_b",
	"climber_a",
	"climber_b",
	"all_rounder",
	"all_rounder",
];

export const DEFAULT_CURRENT_TEAM = {
	id: 0,
	raceId: 0,
	userId: 0,
	points: 0,
	stageNumber: 0,
	stages: [],
};
