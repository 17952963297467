import {IStore} from "modules/types";
import {createSelector} from "reselect";
import {eq, filter, find, findLast, flatMap} from "lodash";
import {RaceStageStatusEnum, RaceStatusEnum} from "modules/constants/enums";
import {IRace} from "modules/types/races";
import moment from "moment-timezone";

export const getRacesState = ({races}: IStore) => races;

export const getRaces = createSelector([getRacesState], (races) => races);

const getFirstScheduledRace = (races: IRace[]) =>
	find(races, {
		status: RaceStatusEnum.Scheduled,
	});

export const getFirstScheduledStage = (race: IRace | undefined) =>
	find(race?.stages, ({status}) => eq(status, RaceStatusEnum.Scheduled));

/**
 * Returns race in the following sequence:
 * active (it's a current race)
 * or scheduled with confirmed riders (we should show scheduled race only if confirmed riders available)
 * or last complete (there may be a case when race is complete but not riders confirmed for the next one)
 * or the first scheduled (for the start of the seasons, when no other races are available)
 */
const findActualRace = (races: IRace[]) => {
	const {Active, Scheduled, Complete} = RaceStatusEnum;

	const actualRace = find(races, ({status, is_riders_confirmed}) => {
		if (eq(status, Active)) {
			return true;
		}

		return status === Scheduled && is_riders_confirmed;
	});

	return actualRace || findLast(races, {status: Complete}) || getFirstScheduledRace(races);
};

const findActualStage = (race?: IRace) => {
	const {Active, Complete} = RaceStageStatusEnum;

	const nextStage = getFirstScheduledStage(race);
	const actualStage = findLast(race?.stages, ({status}) => [Active, Complete].includes(status));

	if (!actualStage) {
		/**
		 * There is no Active or Complete stages.
		 * I.e. race no started yet. Return first scheduled stage.
		 */
		return nextStage;
	}

	if (actualStage?.status === Complete && nextStage) {
		/**
		 * Actual stage is Complete and next stage exist.
		 * The scheduled stage should be actual after midnight by CET timezone.
		 */
		const stageStartDate = moment.tz(actualStage.date_start, "CET");
		const switchToStageAtDate = stageStartDate.startOf("day");

		if (moment().isAfter(switchToStageAtDate)) {
			return nextStage;
		}
	}

	return actualStage;
};

export const getActualRace = createSelector([getRacesState], (races) => findActualRace(races));
export const getActualStage = createSelector([getActualRace], (race) => findActualStage(race));

export const getFirstRace = createSelector([getRacesState], (races) => getFirstScheduledRace(races));
export const getFirstStageFromFirstRace = createSelector([getFirstRace], (race) => getFirstScheduledStage(race));

export const getRaceById = (races: IRace[], raceId: number) => find(races, {id: raceId});
export const getStageById = (race: IRace | undefined, stageId: number) => find(race?.stages, {id: stageId});

export const getStageByIDSelector = createSelector(getRacesState, (races) => (stageID: number) => {
	const stages = flatMap(races, (race) => race.stages);
	return stages.find(({id}) => id === stageID);
});

export const getRaceActualStage = createSelector([getRacesState], (races) => (raceId: number) => {
	const race = getRaceById(races, raceId);
	return findActualStage(race);
});

export const getRaceStages = createSelector([getRacesState], (races) => (raceId: number) => {
	const race = getRaceById(races, raceId);
	return race?.stages || [];
});
export const getRaceNotScheduledStages = createSelector([getRacesState], (races) => (raceId: number) => {
	const race = getRaceById(races, raceId);
	return filter(race?.stages, (stage) => stage.status !== RaceStageStatusEnum.Scheduled);
});

export const getActiveOrCompletedRace = createSelector([getRacesState], (races) =>
	races.filter((race) => [RaceStatusEnum.Active, RaceStatusEnum.Complete].indexOf(race.status) > -1)
);
