import {createAction} from "redux-act";
import {IUser} from "modules/types/user";
import {IDictionary, IFormEventValue, IFormState} from "modules/types";

export const setAuthedUser = createAction<IUser>();
export const fetchUser = createAction();
export const clearUser = createAction();
export const setIsUserFetched = createAction();
export const askUserToRecover = createAction();
export const runRecoverProcess = createAction();
export const setRecoveredSuccess = createAction();

export const fillUserFormData = createAction("fillUserFormData");
export const fillUserFormDataSuccess = createAction<IDictionary<IFormState>>("fillUserFormData");

export const setUserFormData = createAction<IFormEventValue>();
export const setUserFormDataError = createAction<{[x: string]: string}>();
export const validateUserFormData = createAction<IFormEventValue>();
export const updateUserFormData = createAction<{[x: string]: string}>();
export const submitUserFormData = createAction();
