import {IDictionary, IFormEventValue, IFormState, ISagaAction} from "modules/types";
import {validateFieldStore} from "modules/utils/helpers/validations";
import {
	askUserToRecover,
	clearAuthStore,
	clearUser,
	setForgotPassError,
	setForgotPassOpen,
	setGlobalPreloaderState,
	setIsForgotEmailSent,
	setIsResetConfirmOpen,
	setLoginError,
	setRegisterError,
	setRegistrationAllowed,
	setRegistrationStep,
	setResetPassError,
	setResetPassOpen,
	showGlobalError,
	updateForgotPassValues,
	updateLoginValues,
	updateRegisterValues,
	updateResetPassValues,
} from "modules/actions";
import {put, call, select} from "typed-redux-saga";
import {
	getForgotPassState,
	getLoginState,
	getRegistrationState,
	getResetPassState,
	getResetPassToken,
} from "modules/selectors/auth";
import {Api} from "modules/utils/Api";
import {chain, mapValues, identity} from "lodash";
import {isFormStateValidationErrors} from "modules/utils/helpers/auth";
import {Storage} from "modules/utils/Storage";
import moment from "moment";
import {setAuthedUser} from "modules/actions/user";
import {User} from "modules/utils/User";

const updateAuthTmpStorage = (key: string, value: IDictionary<IFormState>) => {
	Storage.SET(key, value);
};

export const setLoginFormValuesSaga = function* ({payload}: ISagaAction<IFormEventValue>) {
	yield put(
		updateLoginValues({
			name: payload.name,
			value: payload.value,
		})
	);
};
export const validateLoginValueSaga = function* ({payload}: ISagaAction<IFormEventValue>) {
	const loginState = yield* select(getLoginState);
	const validation = validateFieldStore(payload, loginState);
	if (validation) {
		yield* put(
			setLoginError({
				name: payload.name,
				error: validation.errorText,
			})
		);
	}
};

export const submitLoginFormSaga = function* () {
	const loginState = yield* select(getLoginState);
	const hasError = isFormStateValidationErrors(loginState);
	if (hasError) {
		return;
	}
	const sendData = mapValues(loginState, (item) => item.value);
	try {
		yield* put(setGlobalPreloaderState(true));
		const {
			success: {user},
		} = yield* call(Api.Auth.login, sendData);
		yield put(setAuthedUser(user));

		if (!user.recovered) {
			yield* put(askUserToRecover());
		}
	} catch (e) {
		yield* put(showGlobalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const setRegisterFormValuesSaga = function* ({payload}: ISagaAction<IFormEventValue>) {
	yield put(
		updateRegisterValues({
			name: payload.name,
			value: payload.value,
		})
	);

	const registrationState = yield* select(getRegistrationState);
	updateAuthTmpStorage("registrationForm", registrationState);
};
export const validateRegisterValueSaga = function* ({payload}: ISagaAction<IFormEventValue>) {
	const registrationState = yield* select(getRegistrationState);
	const validation = validateFieldStore(payload, registrationState);
	if (validation) {
		yield* put(
			setRegisterError({
				name: payload.name,
				error: validation.errorText,
			})
		);
	}
};

export const submitRegisterFormSaga = function* ({payload}: ISagaAction<number>) {
	const registrationState = yield* select(getRegistrationState);
	const hasError = isFormStateValidationErrors(registrationState);

	if (hasError) {
		return;
	}
	if (payload === 1) {
		yield put(setRegistrationStep(2));
		return;
	}

	const sendData = chain(registrationState)
		.omit("confirmEmail", "confirmPassword", "terms")
		.omitBy((item) => !identity(item.value))
		.mapValues((item, key) => {
			if (!item) {
				return null;
			}
			if (item.value === "true") {
				return true;
			}
			if (item && item.value === "false") {
				return false;
			}
			if (key === "birthday") {
				return moment(item.value, "DD/MM/YYYY").format("YYYY-MM-DD");
			}
			return item.value;
		})
		.value();

	try {
		yield* put(setGlobalPreloaderState(true));
		const {success} = yield* call(Api.Auth.register, sendData);
		yield put(setAuthedUser(success.user));
		yield put(clearAuthStore());
		Storage.REMOVE("registrationForm");
	} catch (e) {
		yield* put(showGlobalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const userLogoutSaga = function* () {
	try {
		yield* put(setGlobalPreloaderState(true));
		yield* call(Api.Auth.logout);
		User.CLEAR();
		yield* put(clearUser());
		yield* put(clearAuthStore());
		localStorage.removeItem("codeForJoin");
		sessionStorage.removeItem("hideBanner");
	} catch (e) {
		yield* put(showGlobalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const setForgotPassValuesSaga = function* ({payload}: ISagaAction<IFormEventValue>) {
	yield put(
		updateForgotPassValues({
			name: payload.name,
			value: payload.value,
		})
	);
};
export const validateForgotPassSaga = function* ({payload}: ISagaAction<IFormEventValue>) {
	const forgotPass = yield* select(getForgotPassState);
	const validation = validateFieldStore(payload, forgotPass);
	if (validation) {
		yield* put(
			setForgotPassError({
				name: payload.name,
				error: validation.errorText,
			})
		);
	}
};

export const submitForgotPassSaga = function* () {
	const forgotPass = yield* select(getForgotPassState);
	const hasError = isFormStateValidationErrors(forgotPass);
	if (hasError) {
		return;
	}
	const sendData = mapValues(forgotPass, (item) => item.value);
	try {
		yield* put(setGlobalPreloaderState(true));
		yield* call(Api.Auth.resetPasswordRequest, sendData);
		yield put(setIsForgotEmailSent(true));
		yield put(setForgotPassOpen(false));
	} catch (e) {
		yield* put(showGlobalError(e));
		yield put(setForgotPassOpen(false));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const setResetPassValuesSaga = function* ({payload}: ISagaAction<IFormEventValue>) {
	yield put(
		updateResetPassValues({
			name: payload.name,
			value: payload.value,
		})
	);
};
export const validateResetPassSaga = function* ({payload}: ISagaAction<IFormEventValue>) {
	const resetPass = yield* select(getResetPassState);
	const validation = validateFieldStore(payload, resetPass);
	if (validation) {
		yield* put(
			setResetPassError({
				name: payload.name,
				error: validation.errorText,
			})
		);
	}
};

export const submitResetPassSaga = function* () {
	const resetPass = yield* select(getResetPassState);
	const token = yield* select(getResetPassToken);

	const hasError = isFormStateValidationErrors(resetPass);
	if (hasError) {
		return;
	}
	const sendData = chain(resetPass)
		.omit("confirmPassword")
		.mapValues((item) => item.value)
		.value();
	sendData.token = token;
	try {
		yield* put(setGlobalPreloaderState(true));
		yield* call(Api.Auth.resetPassword, sendData);
		yield put(setIsResetConfirmOpen(true));
		yield put(setResetPassOpen({isOpen: false, token: ""}));
	} catch (e) {
		yield* put(showGlobalError(e));
		yield put(setResetPassOpen({isOpen: false, token: ""}));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const removeAccountSaga = function* () {
	try {
		yield* put(setGlobalPreloaderState(true));
		yield* call(Api.Auth.deactivate_account);
		yield* put(clearUser());
		User.CLEAR();
	} catch (e) {
		yield* put(showGlobalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const getRegistrationAllowedSaga = function* () {
	try {
		yield* put(setGlobalPreloaderState(true));
		const {success} = yield* call(Api.Auth.registerAllowed);
		yield* put(setRegistrationAllowed(success.isAllowed));
	} catch (e) {
		yield* put(showGlobalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};
