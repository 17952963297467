import {IStore} from "modules/types";
import {createSelector} from "reselect";
export const getMyLeaguesStore = (state: IStore) => state.leagues;

export const getMyLeagues = createSelector([getMyLeaguesStore], ({leagues}) => leagues);
export const getLeaguesForJoin = createSelector([getMyLeaguesStore], ({leagues}) => leagues);
export const hasNextLeagues = createSelector([getMyLeaguesStore], ({nextPage}) => nextPage);
export const getCurrentLeague = createSelector([getMyLeaguesStore], ({league}) => league);
export const getJoinedLeagues = createSelector([getMyLeaguesStore], ({joinedCodes}) => joinedCodes);
export const getIsJoined = createSelector([getMyLeaguesStore], ({isJoined}) => isJoined);
export const getIsFetched = createSelector([getMyLeaguesStore], ({isFetched}) => isFetched);

export const isCommissioner = createSelector(
	(state: IStore) => state.leagues.league,

	({user}: IStore) => user,
	(league, user) => league.userId === user.userObj.id
);
