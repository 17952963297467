import {IStore} from "modules/types";

export const getLoginState = ({auth}: IStore) => auth.loginForm;
export const getRegistrationState = ({auth}: IStore) => auth.registrationForm;
export const getRegistrationStep = ({auth}: IStore) => auth.registrationStep;

export const getForgotPassOpen = ({auth}: IStore) => auth.isForgotPassOpen;
export const getForgotPassState = ({auth}: IStore) => auth.forgotPassForm;
export const getForgotEmailSentOpen = ({auth}: IStore) => auth.isForgotEmailSent;

export const getResetPassOpen = ({auth}: IStore) => auth.isResetPassOpen;
export const getResetPassState = ({auth}: IStore) => auth.resetPassForm;
export const getIsResetConfirmOpen = ({auth}: IStore) => auth.isResetConfirmOpen;
export const getResetPassToken = ({auth}: IStore) => auth.resetPassToken;
