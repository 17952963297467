import {call, put, take} from "typed-redux-saga";
import {fetchRidersFailed, fetchRidersSuccess, setSelectedRaceId} from "modules/actions";
import {SagaIterator} from "redux-saga";
import {Api} from "modules/utils/Api";

export const fetchRidersSaga = function* (): SagaIterator {
	while (true) {
		const {payload} = yield* take(setSelectedRaceId);

		if (!payload) {
			return;
		}

		try {
			const riders = yield* call(Api.JSON.riders, payload);
			yield* put(fetchRidersSuccess(riders));
		} catch (e) {
			yield* put(fetchRidersFailed());
			console.log(e);
		}
	}
};
