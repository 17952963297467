import {SagaIterator} from "redux-saga";
import {call, put} from "typed-redux-saga";
import {Api} from "modules/utils/Api";
import {fetchCountriesSuccess} from "modules/actions/countries";
import {showGlobalError} from "modules/actions";

// const firstCountries = ['UK', 'US', 'IT', 'ES', 'FR', 'BE', 'AU', 'NZ'];

export const fetchCountriesSaga = function* (): SagaIterator {
	try {
		const countries = yield* call(Api.JSON.countries);
		yield* put(fetchCountriesSuccess(countries));
	} catch (e) {
		yield* put(showGlobalError(e));
	}
};
