import {media} from "assets/css/media";
import React from "react";
import styled, {css} from "styled-components";

export const PureButton = styled.button`
	border: 0;
	padding: 0;
	margin: 0;
	background: none;
	cursor: pointer;
`;

export interface IButtonProps {
	/**
	 * Define button size. Font-size, width and height will vary depending on this field.
	 */
	size?: "big" | "medium" | "small";
	color?: string;
	hover_color?: string;
	onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
	is_white_background?: true;
}

interface IDisabledStyles {
	is_white_background?: true;
}

const getDisabledStyles = ({is_white_background}: IDisabledStyles) => {
	if (is_white_background) {
		return css`
			background: #cccccc;
			color: #ffffff;
		`;
	}

	return css`
		opacity: 0.5;
		background: transparent;
		color: #000;
	`;
};

const getSizeStyle = ({size = "medium"}: IButtonProps) => {
	return {
		big: css`
			width: 100%;
		`,
		medium: css`
			min-width: 220px;
		`,
		small: css`
			min-width: 150px;
		`,
	}[size];
};

export const Button = styled.button<IButtonProps>`
	border: none;
	background-color: #603f99;
	color: #ffffff;
	cursor: pointer;
	padding: 3px 10px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 14px;
	height: 40px;
	border-radius: 1px;
	text-transform: uppercase;
	font-weight: 600;
	${getSizeStyle};
	transition: all 0.5s;
	box-sizing: border-box;
	font-family: "Source Sans Pro", sans-serif;
	outline: 0;

	@media screen and (max-width: 640px) {
		min-width: 125px;
	}

	&:hover {
		background-color: #442a70;
		transition: background-color 0.25s ease-out;
	}

	&:disabled {
		cursor: default;
		${getDisabledStyles};
		&:hover {
			${getDisabledStyles}
		}
	}
`;

const getSecondaryDisabledStyles = ({is_white_background}: IDisabledStyles) => {
	if (is_white_background) {
		return css`
			color: #cccccc;
			border-color: #cccccc;
		`;
	}

	return css`
		color: #fff;
		opacity: 0.5;
	`;
};

export const SecondaryButton = styled(Button)<IButtonProps>`
	border: 1px solid #603f99;
	height: 40px;
	width: 200px;
	border-radius: 2px;
	background-color: #ffffff;
	color: #603f99;
	font-family: "Source Sans Pro", sans-serif;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 18px;
	text-align: center;
	transition: all 0.25s ease-out;
	&:hover {
		transition: all 0.25s ease-out;
		background: #603f99;
		box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
		color: #fff;
		border-color: #603f99;
	}

	&:disabled {
		background: transparent;
		${getSecondaryDisabledStyles}
		&:hover {
			box-shadow: none;
			background: transparent;
			${getSecondaryDisabledStyles}
		}
	}
	@media (max-width: ${media.mobile}) {
		width: 100%;
	}
`;
