import {media} from "assets/css/media";
import styled from "styled-components";

export const Button = styled.button`
	height: 40px;
	max-width: 220px;
	width: 100%;
	border-radius: 2px;
	background-color: #603f99;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s linear;
	color: #fff;
	font-size: 14px;
	font-weight: bold;
	border: 1px solid transparent;
	cursor: pointer;
	&:hover {
		background-color: #573888;
		&:after {
			background-color: transparent;
		}
	}

	&:disabled {
		background-color: #d2d2d2;
		cursor: not-allowed;
	}
`;

export const ButtonArrow = styled.button`
	height: 15px;
	width: 15px;
	border-right: 3px solid #000;
	border-bottom: 3px solid #000;
	border-left: none;
	border-top: none;
	background: transparent;
	cursor: pointer;
	transform: translateY(-50%) rotate(-45deg);
	position: absolute;
	right: 50px;
	top: 50%;
	outline: none;
	transition: all 0.3s linear;
	&:focus {
		outline: none;
	}
	&:hover {
		right: 46px;
	}
	@media (max-width: ${media.mobile}) {
		right: 33px;
		&:hover {
			right: 30px;
		}
	}
`;
export const LoadMoreBtn = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s linear;
	box-sizing: border-box;
	height: 40px;
	max-width: 240px;
	width: 96%;
	border: 1px solid #603f99;
	border-radius: 2px;
	background-color: #ffffff;
	color: #603f99;
	font-family: "Source Sans Pro", sans-serif;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 18px;
	text-align: center;
	cursor: pointer;
	margin: 0 auto 60px;

	&:hover {
		background-color: #603f99;
		color: #fff;
	}

	&:disabled {
		background-color: #d2d2d2;
		cursor: not-allowed;
	}
`;
