import * as React from "react";
import styled from "styled-components";

const RadioStyled = styled.input`
	display: none;
`;

const RadioButtonWrapper = styled.div`
	position: relative;
	display: inline-flex;
	width: 100%;
	&.disabled {
		&:after {
			content: "";
			background: #f7f7f7;
			position: absolute;
			width: 100%;
			height: 100%;
			opacity: 0.3;
		}
	}

	@media screen and (max-width: 840px) {
		width: 100%;
	}
`;

interface IRadioButtonStyled {
	readonly isFirst: boolean;
	readonly isLast: boolean;
}

const RadioButtonStyled = styled.label`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30px;
	background-color: transparent;
	color: #B8B8B8;;
	border: 1px solid transparent;
	border-radius: 1px;
	flex: 1 1 auto;
	cursor: pointer;
	min-width: 150px;
	width: 50%;
	font-size: 14px;
	margin-bottom: 0!important;

	@media screen and (max-width: 840px) {
		min-width: auto;
	}
	&:first-of-type {
		margin-right: 5px;
	}

	${({isFirst}: IRadioButtonStyled) => (isFirst ? "border-radius: 1px 0 0 1px;" : "")};

	${({isLast}: IRadioButtonStyled) => (isLast ? "border-radius: 0 1px 1px 0;" : "")};
	
	${RadioStyled}:checked + & {
		background-color: #603F99;
		color: #FFFFFF;
	}
`;

interface IOption {
	readonly value: number | string;
	readonly text: string;
}

interface IProps {
	readonly name: string;
	readonly disabled?: boolean;
	readonly options: IOption[];
	form_value: string;
	onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
}

const compare = (value: string | number, to_compare: string | number) => value === to_compare;
const getClassName = (comparator: boolean) => (comparator ? "disabled" : "");
export const FormRadioButtons = ({name, options, disabled, form_value, onChange}: IProps) => (
	<RadioButtonWrapper className={getClassName(Boolean(disabled))}>
		{options
			? options.map(({value, text}, index) => (
					<React.Fragment key={index}>
						<RadioStyled
							type="radio"
							id={`${name}_${value}`}
							name={name}
							value={value}
							checked={compare(value, form_value)}
							onChange={onChange}
							disabled={disabled}
						/>
						<RadioButtonStyled htmlFor={`${name}_${value}`} isFirst={compare(index, 0)} isLast={compare(index, options.length - 1)}>{text}</RadioButtonStyled>
					</React.Fragment>
			  ))
			: null}
	</RadioButtonWrapper>
);
