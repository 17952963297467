export enum RaceStatusEnum {
	Scheduled = "scheduled",
	Active = "active",
	Complete = "complete",
}

export enum RaceTypeEnum {
	OneDay = "one_day",
	Week = "week",
	GrandTour = "grand_tour",
}

export enum StageProfileEnum {
	ITT = "itt",
	Hills = "hills",
	Flat = "flat",
	Climbing = "mountains",
	NA = "N/A",
}

export enum RaceStageStatusEnum {
	Scheduled = "scheduled",
	Active = "active",
	Complete = "complete",
}

export enum RiderPositionEnum {
	AllRounder = "all_rounder",
	MarqueeA = "marquee_a",
	MarqueeB = "marquee_b",
	SprinterA = "sprinter_a",
	SprinterB = "sprinter_b",
	ClimberA = "climber_a",
	ClimberB = "climber_b",
}

export enum RiderStatusEnum {
	Active = "active",
	ELIMINATED = "eliminated",
}

export enum LeaguePrivacyType {
	Private = "private",
	Public = "public",
}

export enum RequestState {
	IDLE,
	Requested,
	Received,
	Error,
}
