import React, {useState, useCallback, forwardRef, ForwardedRef} from "react";
import styled, {css} from "styled-components";
import edit from "assets/img/icons/edit.svg";
import search from "assets/img/icons/search.svg";
import searchRankings from "assets/img/icons/search-rankings.svg";
import error from "assets/img/icons/error.svg";
import email from "assets/img/icons/email.svg";
import password from "assets/img/icons/password.svg";
import emailError from "assets/img/icons/email-error.svg";
import valid from "assets/img/icons/valid.svg";
import lock from "assets/img/icons/lock.svg";
import {ElementsWrapper, ErrorText, Label} from "./index";
import showIcon from "assets/img/icons/IconShowPass.svg";
import hideIcon from "assets/img/icons/IconHidePass.svg";

export type IconType = "search" | "searchRankings" | "edit" | "error" | "none" | "email" | "password" | "valid" | "lock";

export interface IInputProps {
	onClick?: (e: React.SyntheticEvent<HTMLInputElement>) => void;
	onChange?: (e: React.SyntheticEvent<HTMLInputElement>) => void;
	icon_type?: IconType;
	label?: string;
	showPassword?: boolean;
	tooltipText?: string;
	errorText?: string;
	ref?: ForwardedRef<HTMLInputElement>;
}

const getTypeIconInput = ({icon_type = "none"}: IInputProps) => {
	return {
		edit: css`
			background: url(${edit}) no-repeat right 20px center #fafafa;
			background-size: 27px;
		`,
		search: css`
			background: url(${search}) no-repeat right 20px center #ffffff;
			background-size: 16px;
		`,
		searchRankings: css`
			background: url(${searchRankings}) no-repeat right 20px center #ffffff;
			background-size: 16px;
		`,
		error: css`
			background: url(${error}) no-repeat right 20px center #ffeff1 !important;
		`,
		email: css`
			background: url(${email}) no-repeat left 10px center transparent !important;

			&.error {
				background: url(${emailError}) no-repeat left 10px center transparent !important;
			}
		`,
		valid: css`
			background: url(${valid}) no-repeat right 20px center #f6f6f8 !important;
		`,
		lock: css`
			background: url(${lock}) no-repeat right 10px center #f6f6f8 !important;
		`,
		password: css`
			background: url(${password}) no-repeat left 13px center transparent !important;
		`,
		none: css`
			background: #fff;
		`,
	}[icon_type];
};

/**
 * Input
 */
export const InputElement = styled.input<IInputProps>`
	border: 1px solid #ccc;
	box-sizing: border-box;
	padding: 0 10px;
	border-radius: 2px;
	display: inline-flex;
	font-size: 12px;
	height: 40px;
	max-width: 560px;
	line-height: 18px;
	${getTypeIconInput};

	&:disabled {
		opacity: 0.7;
	}

	&::-ms-input-placeholder,
	&:-ms-input-placeholder {
		color: #a3a3a3 !important;
		font-weight: 400 !important;
		opacity: 0.7 !important;
	}

	&::placeholder {
		color: #555555;
		font-weight: 400;
		opacity: 0.7;
	}

	&[type="checkbox"] {
		min-width: auto;
	}

	&.valid {
		border-color: #f6f6f8 !important;
		//background: #F6F6F8 !important;
	}

	&.error {
		border-color: #ffeff1 !important;
		color: #e02120 !important;
	}

	input.error:-internal-autofill-selected {
		border-color: #ffeff1 !important;
		color: #e02120 !important;
		background: #ffeff1 !important;
	}

	input {
		&:-webkit-autofill,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:active,
		&:-webkit-autofill:hover {
			background-color: inherit !important;
			background-clip: content-box;
		}
	}
	&.validated-input:valid {
		background: url(${valid}) no-repeat right 20px center #f6f6f8 !important;
	}
`;

const InputInnerWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	position: relative;
`;

const ShowButton = styled.div`
	position: absolute;
	right: 13px;
	top: 50%;
	transform: translateY(-50%);
	width: auto !important;
	display: inline;
	cursor: pointer;
	font-size: 10px;
	font-weight: bold;
	letter-spacing: 1px;
	user-select: none;
	img {
		width: 20px;
	}
`;

type IProps = React.InputHTMLAttributes<HTMLInputElement> & IInputProps;

const InputComponent: React.ForwardRefRenderFunction<HTMLInputElement, IProps> = (
	{label, type, showPassword, tooltipText = "", errorText, className, ...props},
	ref
) => {
	const [typeState, setType] = useState(type);

	const toggleType = useCallback(() => {
		setType(typeState === "password" ? "text" : "password");
	}, [typeState]);
	// const tooltipId = uniqueId(props.name);
	// const showTooltip = () => tooltipText && !errorText && !props.icon_type;
	return (
		<ElementsWrapper className={className} isDisabled={props.disabled}>
			{label ? <Label>{label}</Label> : null}
			<InputInnerWrapper>
				<InputElement type={typeState} {...props} ref={ref} />
				{showPassword && type === "password" && (
					<ShowButton onClick={toggleType}>
						{typeState === "password" ? (
							<img src={showIcon} alt="show icon" />
						) : (
							<img src={hideIcon} alt="show icon" />
						)}
					</ShowButton>
				)}
			</InputInnerWrapper>
			{errorText ? <ErrorText>{errorText}</ErrorText> : null}
		</ElementsWrapper>
	);
};

export const Input = forwardRef(InputComponent);
