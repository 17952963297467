import {Api} from "modules/utils/Api";
import {SagaIterator} from "redux-saga";
import {call, put, select} from "typed-redux-saga";
import {ISagaAction} from "modules/types";
import {ILeagueRankFilter, IRankingFilter, IRankingFilterRace} from "modules/types/rankings";
import {
	fetchLeagueRankingsSuccess,
	fetchOverallRankingsSuccess,
	setGlobalPreloaderState,
	showGlobalError,
} from "modules/actions";
import {getRankings} from "modules/selectors/rankings";

export const fetchOverallRankingsSaga = function* ({payload}: ISagaAction<IRankingFilter>): SagaIterator {
	try {
		const rankings = yield* select(getRankings);
		yield* put(setGlobalPreloaderState(true));
		const {success} = yield* call(Api.Rankings.overall_rankings, payload);
		const rankingsToSave = payload.page ? [...rankings, ...success.rankings] : success.rankings;

		yield* put(
			fetchOverallRankingsSuccess({
				rankings: rankingsToSave,
				nextPage: success.nextPage,
			})
		);
	} catch (e) {
		yield* put(showGlobalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const fetchOverallRankingsRaceSaga = function* ({payload}: ISagaAction<IRankingFilterRace>): SagaIterator {
	try {
		const rankings = yield* select(getRankings);
		yield* put(setGlobalPreloaderState(true));
		const {success} = yield* call(Api.Rankings.overall_rankings_race, payload);
		const rankingsToSave = payload.page ? [...rankings, ...success.rankings] : success.rankings;

		yield* put(
			fetchOverallRankingsSuccess({
				rankings: rankingsToSave,
				nextPage: success.nextPage,
			})
		);
	} catch (e) {
		yield* put(showGlobalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const fetchLeagueRankingsSaga = function* ({payload}: ISagaAction<ILeagueRankFilter>): SagaIterator {
	try {
		const rankings = yield* select(getRankings);
		yield* put(setGlobalPreloaderState(true));
		const {success} = yield* call(Api.Rankings.league_rankings, payload);
		const rankingsToSave = payload.page ? [...rankings, ...success.rankings] : success.rankings;
		yield* put(
			fetchLeagueRankingsSuccess({
				rankings: rankingsToSave,
				nextPage: success.nextPage,
			})
		);
	} catch (e) {
		yield* put(showGlobalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const fetchLeagueRankingsRaceSaga = function* ({payload}: ISagaAction<ILeagueRankFilter>): SagaIterator {
	try {
		const rankings = yield* select(getRankings);
		yield* put(setGlobalPreloaderState(true));
		const {success} = yield* call(Api.Rankings.league_rankings_race, payload);
		const rankingsToSave = payload.page ? [...rankings, ...success.rankings] : success.rankings;

		yield* put(
			fetchLeagueRankingsSuccess({
				rankings: rankingsToSave,
				nextPage: success.nextPage,
			})
		);
	} catch (e) {
		yield* put(showGlobalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};
