import React from "react";
import {Modal} from "components/Modal";
import styled from "styled-components";
import logo from "assets/img/velon-logo.svg";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "components/Buttons";
import {getIsResetConfirmOpen} from "modules/selectors/auth";
import {setIsResetConfirmOpen} from "modules/actions";

const Logo = styled.img`
	max-width: 154px;
	width: 100%;
	margin: 0 auto 20px;
	display: block;
`;
const Title = styled.div`
	font-size: 16px;
	font-weight: bold;
	line-height: 22px;
	text-align: center;
	margin-bottom: 6px;
`;
const ConfirmButton = styled(Button)`
	margin: 0 auto;
`;

export const ResetPassConfirmModal: React.FC = () => {
	const dispatch = useDispatch();
	const isOpen = useSelector(getIsResetConfirmOpen);

	const {t} = useTranslation();

	const onCloseHandler = () => {
		dispatch(setIsResetConfirmOpen(false));
	};
	return isOpen ? (
		<Modal onClose={onCloseHandler}>
			<Logo src={logo} />
			<Title>{t("forget_password_form_confirmation_text2")}</Title>
			<ConfirmButton type={"submit"} onClick={onCloseHandler}>
				{t("forget_password_form_cta3")}
			</ConfirmButton>
		</Modal>
	) : null;
};
