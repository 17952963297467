import {isString} from "lodash";
import {SagaIterator} from "redux-saga";
import {INormalizedErrors, ISagaAction} from "modules/types";
import {ApiError} from "modules/utils/Api/ApiError";
import {User} from "modules/utils/User";
import {getIsUserAuthed} from "modules/selectors/user";
import {call, put, select} from "typed-redux-saga";
import {clearUser, showGlobalError} from "modules/actions";

type TPayload = INormalizedErrors;

const onError = {
	*[ApiError.AUTH_REQUIRED](error: TPayload): SagaIterator {
		if (yield* select(getIsUserAuthed)) {
			User.CLEAR();
			localStorage.removeItem("codeForJoin");
			yield* put(clearUser());
		}

		yield* call(onError.DEFAULT, error);
	},
	// eslint-disable-next-line require-yield
	DEFAULT: function* (payload: TPayload) {
		if (isString(payload)) {
			throw new Error(payload);
		}

		if (isString(payload.message)) {
			throw new Error(payload.message);
		}
	},
};

export const errorsManagerSaga = function* ({payload}: ISagaAction<TPayload>): SagaIterator {
	const errorCode = payload.code ?? 0;
	console.log(payload);
	try {
		const onErrorHandler = onError[errorCode] ?? onError.DEFAULT;
		yield* call(onErrorHandler, payload);
	} catch (err) {
		yield* put(
			showGlobalError({
				message: payload.message,
				code: errorCode || 0,
			})
		);
	}
};
