import {get, size, map, trim, isFunction, head, compact, eq, isString} from "lodash";
import {IDictionary, IFormEventValue, IFormState} from "modules/types";
import i18n from "i18next";

const required = (value: string | number | boolean) => !value;

interface IValidateOptions {
	allValues?: IDictionary<IFormState>;
	name?: string;
	title?: string;
	errorsTexts?: IDictionary<string>;
}

type TValidation = (value: string | number | boolean, options: IValidateOptions) => string | false;
const maxFunction = (value: string | number | boolean, max: number) => {
	if (isString(value) && size(value) > max) {
		return `Max ${max} Characters`;
	}
	return false;
};
const minFunction = (value: string | number | boolean, min: number) => {
	if (isString(value) && size(value) < min) {
		return `Min ${min} Characters`;
	}
	return false;
};
const getTranslationError = (key: string | undefined) => {
	return key ? i18n.t(key) : "";
};
export const validations: IDictionary<TValidation> = {
	required: (value: string | number | boolean, options) => {
		if (required(value)) {
			const customError = getTranslationError(options?.errorsTexts?.required);
			const title = get(options, "title", "");
			return customError ? customError : `${title} field required`;
		}
		return false;
	},
	onlyLetters: (value: string | number | boolean, options) => {
		const regrxp = /^[a-zA-Z\s]+$/;
		const test = regrxp.exec(value.toString());
		if (!test) {
			const customError = options?.errorsTexts?.onlyLetters;
			const title = get(options, "title", "");
			return customError ? getTranslationError(customError) : `${title} only letters`;
		}
		return false;
	},
	max40: (value: string | number | boolean) => {
		return maxFunction(value, 40);
	},
	min8: (value: string | number | boolean) => {
		return minFunction(value, 8);
	},
	emailFormat: (value: string | number | boolean, options) => {
		const regrxp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const test = regrxp.exec(value.toString());
		if (!test) {
			const customError = options?.errorsTexts?.emailFormat;
			const title = get(options, "title", "");
			return customError ? getTranslationError(customError) : `${title} should be an email format`;
		}
		return false;
	},
	onlyNumber: (value: string | number | boolean, options) => {
		const regrxp = /^[0-9]+$/;
		const test = regrxp.exec(value.toString());
		if (!test) {
			const customError = options?.errorsTexts?.onlyNumber;
			return customError ? getTranslationError(customError) : `Only numbers required`;
		}
		return false;
	},
	onlyAlfaNumeric: (value: string | number | boolean, options) => {
		const regrxp = /^[a-z0-9]+$/;
		const test = regrxp.exec(value.toString());
		if (!test) {
			const customError = options?.errorsTexts?.onlyAlfaNumeric;
			const title = get(options, "title", "");
			return customError ? getTranslationError(customError) : `${title} should contain only letters and digits`;
		}
		return false;
	},
	matchPassword: (value: string | number | boolean, {allValues, errorsTexts}: IValidateOptions) => {
		const password = get(allValues, "password.value", "");
		if (!eq(password, value)) {
			const customError = errorsTexts?.matchPassword;
			return customError ? getTranslationError(customError) : "Password does not match";
		}

		return false;
	},
	matchEmail: (value: string | number | boolean, {allValues, errorsTexts}: IValidateOptions) => {
		const email = get(allValues, "email.value", "");
		if (!eq(email, value)) {
			const customError = errorsTexts?.matchPassword;
			return customError ? getTranslationError(customError) : "Email does not match";
		}

		return false;
	},
};

export const validateFieldStore = (formField: IFormEventValue, allValues: IDictionary<IFormState>) => {
	const {value, validations: fieldValidations = "", name, title = "", errorsTexts} = formField;
	const validate_options = {
		name,
		title,
		allValues,
		errorsTexts,
	};
	if (!name) {
		return false;
	}
	const functions = [...fieldValidations.split(",")];
	if (size(functions)) {
		const errors = map(functions, (f: string) => {
			const func = validations[trim(f)];
			return isFunction(func) && func(value, validate_options);
		});
		const top_error = head(compact(errors));
		if (top_error) {
			return {
				name,
				errorText: top_error,
			};
		}
	}
	return false;
};
