import {ISagaAction} from "modules/types";
import {all, call, put, select, take} from "typed-redux-saga";
import {getRaceActualStage} from "modules/selectors/races";
import {
	setCaptains,
	setGlobalPreloaderState,
	setLineup,
	setPoolFilterValue,
	setRaceTeam,
	setSelectedRaceId,
	setSelectedStageId,
	setTeamIsFetched,
	showGlobalError,
	updateTradeIn,
} from "modules/actions";
import {
	getCaptainIDs,
	getCurrentTeam,
	getLineup,
	getLineupTradeIn,
	getSelectedRace,
	getSelectedRaceId,
	getSelectedStageId,
} from "modules/selectors/team";
import {getRiderById} from "modules/selectors/riders";
import {DEFAULT_CURRENT_TEAM, LINEUP_POSITION_KEYS} from "modules/constants/team";
import {fetchRacesSaga} from "modules/sagas/races";
import {fetchSquadsSaga} from "modules/sagas/squads";
import {Api} from "modules/utils/Api";
import {compact, find, last} from "lodash";
import {isUserRecovered} from "modules/selectors/user";

export const fetchGameDataSaga = function* () {
	yield* put(setGlobalPreloaderState(true));
	const isRecovered = yield* select(isUserRecovered);
	const requests = [fetchRacesSaga(), fetchSquadsSaga()];

	if (isRecovered) {
		requests.push(getTeamByRaceIdSaga());
	}

	yield* all(requests);
	yield* put(setGlobalPreloaderState(false));
};

export const changeSelectedRaceIdSaga = function* ({payload}: ISagaAction<number>) {
	const selectedStage = yield* select((state) => getRaceActualStage(state)(payload));
	if (!selectedStage) {
		return;
	}
	yield* put(updateTradeIn(null));
	yield* put(setPoolFilterValue({position: "all"}));
	yield* put(setSelectedRaceId(payload));
	yield* put(setSelectedStageId(selectedStage.id));
	yield* put(setGlobalPreloaderState(true));
	yield* call(getTeamByRaceIdSaga);
	yield* put(setGlobalPreloaderState(false));
};

export const setSelectedStageSaga = function* ({payload}: ISagaAction<number>) {
	yield* put(setSelectedStageId(payload));
	const {stages} = yield* select(getCurrentTeam);
	const selectedStageData = find(stages, {stageId: payload}) || {
		lineup: [0, 0, 0, 0, 0, 0, 0, 0],
		captains: [],
	};
	yield* put(updateTradeIn(null));
	yield* put(setPoolFilterValue({position: "all"}));
	yield* put(setLineup(selectedStageData.lineup));
	yield* put(setCaptains(selectedStageData.captains));
};

const getAllRoundersLineup = (lineup: number[], riderId: number): number[] => {
	const getExistRiderLineupPos = lineup.indexOf(riderId);
	let firstEmptyPos = lineup.indexOf(0);
	firstEmptyPos = firstEmptyPos >= 0 ? firstEmptyPos : lineup.length - 1;
	const pos = getExistRiderLineupPos > -1 ? getExistRiderLineupPos : firstEmptyPos;
	lineup[pos] = getExistRiderLineupPos > -1 ? 0 : riderId;
	return lineup;
};

// eslint-disable-next-line complexity
export const changeLineupSaga = function* ({payload}: ISagaAction<number>) {
	const race = yield* select(getSelectedRace);
	let lineup = yield* select(getLineup);
	const captains = yield* select(getCaptainIDs);
	const rider = yield* select((state) => getRiderById(state)(payload));
	let positionIndex = LINEUP_POSITION_KEYS.indexOf(rider?.position || "");

	const allRounderPos = lineup[6] === payload || !lineup[6] ? 6 : 7;
	positionIndex = positionIndex === 6 ? allRounderPos : positionIndex;
	const inPositionRider = lineup[positionIndex];

	if (race?.stages.length && race?.stages.length > 1) {
		lineup[positionIndex] = inPositionRider === payload ? 0 : payload;
	} else {
		lineup = getAllRoundersLineup(lineup, payload);
	}

	yield* put(setLineup([...lineup]));

	if (captains.includes(rider?.id || 0)) {
		yield* put(setRaceTeam({captains: captains.filter((id) => id !== rider?.id)}));
	}
};

export const saveTeamSaga = function* () {
	const raceId = yield* select(getSelectedRaceId);
	const lineup = yield* select(getLineup);
	try {
		yield* put(setGlobalPreloaderState(true));
		const selectedStageId = yield* select(getSelectedStageId);
		const captains = yield* select(getCaptainIDs);
		const {success} = yield* call(Api.Team.saveTeam, {raceId, lineup, captains});
		const stageLineUp = find(success.team.stages, {stageId: selectedStageId}) || last(success.team.stages);

		yield* put(
			setRaceTeam({
				currentTeam: success.team,
				userStats: success.userStats,
				lineup: stageLineUp?.lineup || [0, 0, 0, 0, 0, 0, 0, 0],
				captains: stageLineUp?.captains,
				isSaved: true,
			})
		);
	} catch (e) {
		yield* put(showGlobalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const saveCaptainsSaga = function* ({payload: captainIDs}: ISagaAction<number[]>) {
	const {id: teamID} = yield* select(getCurrentTeam)!;

	if (!teamID || !captainIDs) {
		return;
	}

	try {
		yield* put(setGlobalPreloaderState(true));
		const selectedStageId = yield* select(getSelectedStageId);
		const {success} = yield* call(Api.Team.saveCaptain, {teamID, captains: captainIDs});
		const stageLineUp = find(success.team.stages, {stageId: selectedStageId}) || last(success.team.stages);

		yield* put(
			setRaceTeam({
				currentTeam: success.team,
				lineup: stageLineUp?.lineup || [0, 0, 0, 0, 0, 0, 0, 0],
				captains: stageLineUp?.captains,
				isSaved: true,
			})
		);
	} catch (e) {
		yield* put(showGlobalError(e));
	}

	yield* put(setGlobalPreloaderState(false));
};

export const updateLineupSaga = function* () {
	const {id} = yield* select(getCurrentTeam);
	const lineup = yield* select(getLineup);
	try {
		const captains = yield* select(getCaptainIDs);
		yield* put(setGlobalPreloaderState(true));
		yield* call(Api.Team.updateLineup, id, {lineup, captains});
		yield* put(
			setRaceTeam({
				isSaved: true,
			})
		);
	} catch (e) {
		yield* put(showGlobalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};

// eslint-disable-next-line complexity
export const getTeamByRaceIdSaga = function* () {
	let raceId = yield* select(getSelectedRaceId);
	let selectedStageId = yield* select(getSelectedStageId);

	if (!raceId || !selectedStageId) {
		const {payload} = yield* take(setSelectedRaceId);
		const {payload: stageId} = yield* take(setSelectedStageId);

		if (!payload && !stageId) {
			return;
		}

		raceId = payload;
		selectedStageId = stageId;
	}

	try {
		const {success} = yield* call(Api.Team.getRaceTeam, raceId);
		const stageLineUp = find(success.team.stages, {stageId: selectedStageId}) || last(success.team.stages);
		const lineup = stageLineUp?.lineup || [0, 0, 0, 0, 0, 0, 0, 0];
		const captains = stageLineUp?.captains;

		yield* put(
			setRaceTeam({
				currentTeam: success.team,
				userStats: success.userStats,
				lineup,
				captains,
				isSaved: Boolean(compact(lineup).length),
			})
		);
	} catch (e) {
		yield* put(
			setRaceTeam({
				currentTeam: DEFAULT_CURRENT_TEAM,
				userStats: null,
				captains: [],
				lineup: [0, 0, 0, 0, 0, 0, 0, 0],
				isSaved: false,
			})
		);
		yield* put(setTeamIsFetched(true));
	}
};

export const createAutoPickSaga = function* () {
	const raceId = yield* select(getSelectedRaceId);
	try {
		yield* put(setGlobalPreloaderState(true));
		const selectedStageId = yield* select(getSelectedStageId);
		const lineup = yield* select(getLineup);
		const captains = yield* select(getCaptainIDs);
		const params = {raceId, lineup, captains};
		const {success} = yield* call(Api.Team.createAutoPick, params);
		const stageLineUp = find(success.team.stages, {stageId: selectedStageId}) || last(success.team.stages);

		yield* put(
			setRaceTeam({
				currentTeam: success.team,
				userStats: success.userStats,
				lineup: stageLineUp?.lineup || [0, 0, 0, 0, 0, 0, 0, 0],
				captains: stageLineUp?.captains,
				isSaved: true,
			})
		);
	} catch (e) {
		yield* put(showGlobalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const updateAutoPickSaga = function* () {
	const {id} = yield* select(getCurrentTeam);

	try {
		yield* put(setGlobalPreloaderState(true));
		const selectedStageId = yield* select(getSelectedStageId);
		const lineup = yield* select(getLineup);
		const captains = yield* select(getCaptainIDs);
		const params = {teamId: id, lineup, captains};
		const {success} = yield* call(Api.Team.updateAutoPick, params);

		const stageLineUp = find(success.team.stages, {stageId: selectedStageId}) || last(success.team.stages);
		yield* put(
			setRaceTeam({
				currentTeam: success.team,
				userStats: success.userStats,
				lineup: stageLineUp?.lineup || [0, 0, 0, 0, 0, 0, 0, 0],
				captains: stageLineUp?.captains,
				isSaved: true,
			})
		);
	} catch (e) {
		yield* put(showGlobalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};

// eslint-disable-next-line complexity,sonarjs/cognitive-complexity
export const addRiderToTransferSaga = function* ({payload}: ISagaAction<number>) {
	const currentTeam = yield* select(getCurrentTeam);
	const lineup = yield* select(getLineup);
	const tradeIn = yield* select(getLineupTradeIn);
	const newTradeInRiderId = tradeIn === payload ? null : payload;
	const isInLineUp = lineup.indexOf(payload) > -1;
	const isLineUpTradeInRider = lineup.indexOf(tradeIn || 0) > -1;
	const tradeInRider = yield* select((store) => getRiderById(store)(payload));

	if (isInLineUp && tradeInRider) {
		yield* put(setPoolFilterValue({position: tradeInRider?.position}));
	}
	// Add rider to trade if it was not added
	if (!tradeIn || (!newTradeInRiderId && isInLineUp)) {
		yield* put(updateTradeIn(newTradeInRiderId));
		return;
	}
	// Update tradeIn rider if prev and current in lineup
	if (tradeIn && isInLineUp && isLineUpTradeInRider) {
		yield* put(updateTradeIn(newTradeInRiderId));
		return;
	}
	if (tradeIn && !isLineUpTradeInRider && !isInLineUp) {
		yield* put(updateTradeIn(newTradeInRiderId));
		return;
	}
	if (tradeIn) {
		const oldRiderId = isInLineUp ? payload : tradeIn;
		const newRiderId = !isInLineUp ? payload : tradeIn;

		try {
			yield* put(setGlobalPreloaderState(true));
			const {success} = yield* call(Api.Team.trade, currentTeam.id, {oldRiderId, newRiderId});
			const selectedStageId = yield* select(getSelectedStageId);
			const stageLineUp = find(success.team.stages, {stageId: selectedStageId}) || last(success.team.stages);
			const newLineup = stageLineUp?.lineup || lineup;
			yield* put(
				setRaceTeam({
					currentTeam: success.team,
					userStats: success.userStats,
					lineup: newLineup,
					captains: stageLineUp?.captains,
					isSaved: Boolean(compact(lineup).length),
				})
			);
		} catch (e) {
			yield* put(showGlobalError(e));
		}
	}
	yield* put(updateTradeIn(null));
	yield* put(setPoolFilterValue({position: "all"}));
	yield* put(setGlobalPreloaderState(false));
};

export const rollBackTradeSaga = function* () {
	const {id} = yield* select(getCurrentTeam);
	try {
		yield* put(setGlobalPreloaderState(true));
		const selectedStageId = yield* select(getSelectedStageId);
		const {success} = yield* call(Api.Team.rollBackTrades, id);
		const stageLineUp = find(success.team.stages, {stageId: selectedStageId}) || last(success.team.stages);
		yield* put(
			setRaceTeam({
				currentTeam: success.team,
				userStats: success.userStats,
				lineup: stageLineUp?.lineup || [0, 0, 0, 0, 0, 0, 0, 0],
				isSaved: true,
			})
		);
		yield* put(setPoolFilterValue({position: "all"}));
	} catch (e) {
		yield* put(showGlobalError(e));
		yield* put(setPoolFilterValue({position: "all"}));
	}
	yield* put(setGlobalPreloaderState(false));
};
