import {SagaIterator} from "redux-saga";
import * as actions from "modules/actions";
import {
	getRegistrationAllowedSaga,
	removeAccountSaga,
	setForgotPassValuesSaga,
	setLoginFormValuesSaga,
	setRegisterFormValuesSaga,
	setResetPassValuesSaga,
	submitForgotPassSaga,
	submitLoginFormSaga,
	submitRegisterFormSaga,
	submitResetPassSaga,
	userLogoutSaga,
	validateForgotPassSaga,
	validateLoginValueSaga,
	validateRegisterValueSaga,
	validateResetPassSaga,
} from "modules/sagas/auth";
import {
	getCurrentLeague,
	getLeaguesForJoinSaga,
	getMyLeaguesSaga,
	postCreateLeagueSaga,
	postInviteSaga,
	postJoinToLeagueSaga,
	postleaveLeagueSaga,
	postUpdateLeagueSaga,
	removeLeagueSaga,
} from "./leagues/leagues";
import {fetchRacesSaga} from "modules/sagas/races";
import {fetchSquadsSaga} from "modules/sagas/squads";
import {fork, takeLatest} from "typed-redux-saga";
import {
	fetchUserSaga,
	fillUserFormDataSaga,
	requestUserRecoverSaga,
	setUserFormDataSaga,
	submitUserFormDataSaga,
	validateUserFormDataSaga,
} from "modules/sagas/user";
import {errorsManagerSaga} from "modules/sagas/errors";
import {
	addRiderToTransferSaga,
	changeLineupSaga,
	changeSelectedRaceIdSaga,
	createAutoPickSaga,
	fetchGameDataSaga,
	getTeamByRaceIdSaga,
	rollBackTradeSaga,
	saveCaptainsSaga,
	saveTeamSaga,
	setSelectedStageSaga,
	updateAutoPickSaga,
	updateLineupSaga,
} from "modules/sagas/team";
import {fetchRidersSaga} from "modules/sagas/riders";
import {fetchFaqsSaga, fetchHelpsSaga, postContactFormSaga} from "./help";
import {fetchCountriesSaga} from "./countries";
import {
	fetchLeagueRankingsRaceSaga,
	fetchLeagueRankingsSaga,
	fetchOverallRankingsRaceSaga,
	fetchOverallRankingsSaga,
} from "./rankings";
import {fetchRiderScoreSaga} from "modules/sagas/rider";

/**
 * Root saga that connect sagas with actions.
 */
export default function* rootSaga(): SagaIterator {
	/**
	 * JSONS
	 */
	yield* fork(fetchRidersSaga);
	yield* takeLatest(actions.fetchSquads, fetchSquadsSaga);
	yield* takeLatest(actions.fetchRaces, fetchRacesSaga);
	yield* takeLatest(actions.fetchCountries, fetchCountriesSaga);
	yield* takeLatest(actions.fetchRiderScore, fetchRiderScoreSaga);
	/**
	 * Auth
	 */
	yield* takeLatest(actions.fetchRegistrationAllowed, getRegistrationAllowedSaga);
	yield* takeLatest(actions.setLoginValue, setLoginFormValuesSaga);
	yield* takeLatest(actions.validateLoginValue, validateLoginValueSaga);
	yield* takeLatest(actions.submitLoginForm, submitLoginFormSaga);
	yield* takeLatest(actions.setRegisterValue, setRegisterFormValuesSaga);
	yield* takeLatest(actions.validateRegisterValue, validateRegisterValueSaga);
	yield* takeLatest(actions.submitRegisterForm, submitRegisterFormSaga);
	yield* takeLatest(actions.userLogout, userLogoutSaga);
	yield* takeLatest(actions.removeAccount, removeAccountSaga);
	yield* takeLatest(actions.setForgotPassValue, setForgotPassValuesSaga);
	yield* takeLatest(actions.validateForgotPassValue, validateForgotPassSaga);
	yield* takeLatest(actions.submitForgotPassForm, submitForgotPassSaga);
	yield* takeLatest(actions.setResetPassValue, setResetPassValuesSaga);
	yield* takeLatest(actions.validateResetPassValue, validateResetPassSaga);
	yield* takeLatest(actions.submitResetPassForm, submitResetPassSaga);
	/**
	 * User
	 */
	yield* takeLatest(actions.fetchUser, fetchUserSaga);
	yield* takeLatest(actions.fillUserFormData, fillUserFormDataSaga);
	yield* takeLatest(actions.setUserFormData, setUserFormDataSaga);
	yield* takeLatest(actions.validateUserFormData, validateUserFormDataSaga);
	yield* takeLatest(actions.submitUserFormData, submitUserFormDataSaga);
	yield* takeLatest(actions.runRecoverProcess, requestUserRecoverSaga);
	/**
	 * Leagues
	 */
	yield* takeLatest(actions.fetchMyLeagues, getMyLeaguesSaga);
	yield* takeLatest(actions.fetchLeaguesForJoin, getLeaguesForJoinSaga);
	yield* takeLatest(actions.joinToLeague, postJoinToLeagueSaga);
	yield* takeLatest(actions.fetchThisLeague, getCurrentLeague);
	yield* takeLatest(actions.postCreateLeague, postCreateLeagueSaga);
	yield* takeLatest(actions.postUpdateLeague, postUpdateLeagueSaga);
	yield* takeLatest(actions.postInvite, postInviteSaga);
	yield* takeLatest(actions.leaveLeague, postleaveLeagueSaga);
	yield* takeLatest(actions.removeLeague, removeLeagueSaga);
	/**
	 * Team
	 */
	yield* takeLatest(actions.fetchGameData, fetchGameDataSaga);
	yield* takeLatest(actions.changeSelectedRaceId, changeSelectedRaceIdSaga);
	yield* takeLatest(actions.setSelectedStage, setSelectedStageSaga);
	yield* takeLatest(actions.changeLineUp, changeLineupSaga);
	yield* takeLatest(actions.saveTeam, saveTeamSaga);
	yield* takeLatest(actions.saveNewCaptains, saveCaptainsSaga);
	yield* takeLatest(actions.updateLineup, updateLineupSaga);
	yield* takeLatest(actions.getTeamByRaceId, getTeamByRaceIdSaga);
	yield* takeLatest(actions.createAutoPick, createAutoPickSaga);
	yield* takeLatest(actions.updateAutoPick, updateAutoPickSaga);
	yield* takeLatest(actions.addRiderToTransfer, addRiderToTransferSaga);
	yield* takeLatest(actions.rollBackTrade, rollBackTradeSaga);
	/**
	 * Help
	 */
	yield* takeLatest(actions.postContactForm, postContactFormSaga);
	yield* takeLatest(actions.fetchFaqs, fetchFaqsSaga);
	yield* takeLatest(actions.fetchHelps, fetchHelpsSaga);
	/**
	 * Rankings
	 */
	yield* takeLatest(actions.fetchOverallRankingsAll, fetchOverallRankingsSaga);
	yield* takeLatest(actions.fetchOverallRankingsRace, fetchOverallRankingsRaceSaga);
	yield* takeLatest(actions.fetchLeagueRankingsAll, fetchLeagueRankingsSaga);
	yield* takeLatest(actions.fetchLeagueRankingsRace, fetchLeagueRankingsRaceSaga);
	/**
	 * Other
	 */
	yield* takeLatest(actions.globalError, errorsManagerSaga);
}
