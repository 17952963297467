import {IStore} from "modules/types";
import {createSelector} from "reselect";

export const getUserState = (state: IStore) => state.user.userObj;
export const getIsUserAuthed = (state: IStore) => state.user.isAuthorized;

export const isUserRecovered = createSelector(getUserState, (user) => Boolean(user.recovered));

export const getIsUserFetched = (state: IStore) => state.user.isFetched;
export const getAccountUserForm = (state: IStore) => state.user.accountUserForm;
export const isRecoverModalVisible = (state: IStore) => Boolean(state.user.isRecoverModalVisible);
