import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {BaseContainer} from "components/Layout";
import {DesktopMenu, RightMenu} from "components/Menu/DesktopMenu";
import {MenuToggle} from "components/Menu/MobileToggle";
import {media} from "assets/css/media";
import {MobileMenuAnimated} from "components/Menu/MobileMenuAnimated";
import {useCycle} from "framer-motion";
import logo from "assets/img/velon-logo.svg";
import {LanguageSwitcher} from "components/LanguageSwitcher";

const HeaderWrapper: React.FC = styled.header`
	box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.07);
	background: #f1efee;
	height: 100px;
	display: flex;
	@media (max-width: ${media.mobile}) {
		height: 50px;
	}
`;
const LogoLink = styled(Link)`
	text-decoration: none;
	height: 100%;
	display: flex;
	align-items: center;
`;
const Logo = styled.img``;

export const Header = () => {
	const [isOpen, toggleOpen] = useCycle(false, true);
	return (
		<HeaderWrapper>
			<BaseContainer align={"center"}>
				<LogoLink to="/">
					<Logo src={logo} alt={"Velon Logo"} />
				</LogoLink>
				<DesktopMenu />
				<RightMenu />
				<LanguageSwitcher />
				<MenuToggle isOpen={isOpen} toggleCallback={toggleOpen} />
			</BaseContainer>
			<MobileMenuAnimated isOpen={isOpen} toggleCallback={toggleOpen} />
		</HeaderWrapper>
	);
};
