import {createReducer} from "redux-act";
import {IUserReducer} from "modules/types/reducers";
import {USER__DEFAULT_FORM_STATE, USER_DEFAULT_STATE} from "modules/constants/user";
import {User} from "modules/utils/User";
import {
	askUserToRecover,
	clearUser,
	fillUserFormDataSuccess,
	setAuthedUser,
	setIsUserFetched,
	setRecoveredSuccess,
	updateUserFormData,
} from "modules/actions/user";

const initialState: IUserReducer = {
	userObj: USER_DEFAULT_STATE,
	isAuthorized: User.IS_AUTHENTICATED(),
	isFetched: false,
	accountUserForm: USER__DEFAULT_FORM_STATE,
	isRecoverModalVisible: false,
};

export const user = createReducer<IUserReducer>({}, initialState);
user.on(setAuthedUser, (state, payload) => ({
	...state,
	isAuthorized: true,
	userObj: payload,
	isFetched: true,
}));
user.on(clearUser, () => ({
	...initialState,
	isAuthorized: false,
	isFetched: true,
}));
user.on(setIsUserFetched, (state) => ({
	...state,
	isFetched: true,
}));
user.on(fillUserFormDataSuccess, (state, payload) => ({
	...state,
	accountUserForm: payload,
}));
user.on(updateUserFormData, (state, payload) => ({
	...state,
	accountUserForm: {
		...state.accountUserForm,
		[payload.name]: {
			...state.accountUserForm[payload.name],
			value: payload.value,
			error: "",
		},
	},
}));
user.on(askUserToRecover, (state) => ({
	...state,
	isRecoverModalVisible: true,
}));
user.on(setRecoveredSuccess, (state) => ({
	...state,
	isRecoverModalVisible: false,
}));
