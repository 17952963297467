import {IApiResponse, IError} from "modules/types";

/**
 * Custom Errors handler
 */
export class ApiError extends Error {
	public static AUTH_REQUIRED = 401;
	public static USER_NOT_REGISTERED = 513;

	public static HAS_ERROR({errors}: IApiResponse) {
		return Boolean(Array.isArray(errors) && errors.length);
	}

	/**
	 * Should be called after each requests when we expect some errors or validations
	 */
	public static CHECK(response: IApiResponse, code?: number) {
		if (ApiError.HAS_ERROR(response)) {
			const error = response.errors[0];
			throw new ApiError(error.message, error.code || code, response.errors);
		}
	}

	public code?: number;
	public text?: string;
	public allErrors?: IError[];

	constructor(m: string, code?: number, allErrors?: IError[]) {
		super(m);
		this.message = m;
		this.name = "API Error";
		this.code = code;
		this.allErrors = allErrors;
	}
}

export default ApiError;
