import {createReducer} from "redux-act";
import {IRankingsReducer} from "modules/types/rankings";
import * as actions from "modules/actions";

const initialState: IRankingsReducer = {
	rankings: [],
	nextPage: false,
};

export const rankings = createReducer<IRankingsReducer>({}, initialState);
rankings.on(actions.fetchOverallRankingsSuccess, (state, payload) => ({
	...state,
	rankings: payload.rankings,
	nextPage: payload.nextPage,
}));
// eslint-disable-next-line sonarjs/no-identical-functions
rankings.on(actions.fetchLeagueRankingsSuccess, (state, payload) => ({
	...state,
	rankings: payload.rankings,
	nextPage: payload.nextPage,
}));

rankings.on(actions.clearRankings, () => ({...initialState}));
