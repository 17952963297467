import React, {useEffect, useState, Fragment} from "react";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import {initReactI18next} from "react-i18next";
import {JSON_URL} from "modules/constants";
import {Preloader} from "components/Preloader";
import {useDispatch, useSelector} from "react-redux";
import {fetchRegistrationAllowed, fetchUser} from "modules/actions";
import {getIsUserFetched} from "modules/selectors/user";
import {getIsRegistrationAllowedFetched} from "modules/selectors/global";

export const HOCi18n: React.FC = ({children}) => {
	const dispatch = useDispatch();
	const [initialized, setInitialized] = useState(false);
	const isUserFetched = useSelector(getIsUserFetched);
	const isRegisterAllowedFetched = useSelector(getIsRegistrationAllowedFetched);
	const isShowChildren = initialized && isUserFetched && isRegisterAllowedFetched;
	useEffect((): void => {
		i18n.use(LanguageDetector)
			.use(Backend)
			.use(initReactI18next)
			.init(
				{
					/**
					 * i18nextBrowserLanguageDetector
					 */
					detection: {
						order: ["cookie", "localStorage", "sessionStorage", "navigator"],
					},
					supportedLngs: ["en", "it"],
					backend: {
						crossDomain: true,
						loadPath: `${JSON_URL as string}langs/{{lng}}.json`,
					},
					fallbackLng: "en",
					// debug: ENV !== "production",
					debug: false,
					keySeparator: ".",
					interpolation: {
						escapeValue: false,
						formatSeparator: ",",
					},
					react: {
						useSuspense: true,
					},
				},
				() => {
					setInitialized(true);
					dispatch(fetchRegistrationAllowed());
					// eslint-disable-next-line @typescript-eslint/no-unsafe-call
					dispatch(fetchUser());
				}
			)
			.catch((err) => console.log(err));
	}, [dispatch]);

	return isShowChildren ? <Fragment>{children}</Fragment> : <Preloader />;
};
