import {ILeaguesReducer, LeagueClassType, LeaguePrivacyType, LeagueStatusType} from "modules/types/leagues";
import {createReducer} from "redux-act";
import * as actions from "modules/actions";

const initialState: ILeaguesReducer = {
	league: {
		id: 0,
		name: "",
		userId: 0,
		code: "",
		privacy: LeaguePrivacyType.Public,
		status: LeagueStatusType.Scheduled,
		class: LeagueClassType.Regular,
		raceId: 0,
		description: "",
		numberJoinedUsers: 0,
		userRankings: {
			rank: 0,
			points: 0,
			stagesPoints: [
				{
					stageId: 0,
					points: 0,
				},
			],
		},
	},
	leagues: [],
	nextPage: false,
	joinedCodes: {},
	create_league_process: false,
	update_league_process: false,
	league_updated: false,
	invites_sent: false,
	isJoined: false,
	isFetched: false,
};

export const leagues = createReducer<ILeaguesReducer>({}, initialState);

leagues.on(actions.fetchMyLeaguesSuccess, (state, payload) => ({
	...state,
	leagues: payload.leagues,
	nextPage: payload.nextPage,
}));
// eslint-disable-next-line sonarjs/no-identical-functions
leagues.on(actions.fetchLeaguesForJoinSuccess, (state, payload) => ({
	...state,
	leagues: payload.leagues,
	nextPage: payload.nextPage,
}));
leagues.on(actions.clearLeaguesList, () => ({...initialState}));
leagues.on(actions.fetchThisLeague, (state) => ({
	...state,
	isFetched: false,
}));
leagues.on(actions.fetchThisLeagueSuccess, (state, payload) => ({
	...state,
	league: payload.league,
	isJoined: payload.isJoined,
	isFetched: true,
}));
leagues.on(actions.joinToLeagueSuccess, (state, payload) => ({
	...state,
	joinedCodes: {
		...state.joinedCodes,
		[payload]: true,
	},
}));
leagues.on(actions.rollBackJoinLeague, (state, payload) => ({
	...state,
	joinedCodes: {
		...state.joinedCodes,
		[payload]: false,
	},
}));
leagues.on(actions.clearLeague, (state) => ({
	...state,
	league: {
		...initialState.league,
	},
	leagues: [],
	isJoined: false,
}));
leagues.on(actions.postCreateLeague, (state) => ({
	...state,
	create_league_process: true,
}));
leagues.on(actions.postCreateLeagueSuccess, (state, payload) => ({
	...state,
	league: payload,
	create_league_process: false,
}));
leagues.on(actions.postUpdateLeagueSuccess, (state, payload) => ({
	...state,
	...payload,
}));
leagues.on(actions.postInviteSuccess, (state) => ({
	...state,
	invites_sent: true,
}));
leagues.on(actions.postInviteReset, (state) => ({
	...state,
	invites_sent: false,
}));
