import {getMyLeagues} from "modules/selectors/leagues";
import React from "react";

export interface IShowMyLeaguesPayload {
	leagues?: ILeague[];
	filter?: IJoinFilter;
}

export enum LeaguePrivacyType {
	Private = "private",
	Public = "public",
}

export enum LeagueClassType {
	Regular = "regular",
	Overall = "overall",
}

export enum LeagueStatusType {
	Scheduled = "scheduled",
	Complete = "complete",
}

export interface ILeague {
	id: number;
	name: string;
	userId: number;
	code: string;
	privacy: LeaguePrivacyType;
	status: LeagueStatusType;
	class: LeagueClassType;
	raceId: number;
	description: string;
	numberJoinedUsers: number;
	userRankings: IUserRankings | null;
}

export interface IUserRankings {
	rank: number;
	points: number;
	stagesPoints: IStagePoints[] | null;
}

export interface IStagePoints {
	stageId: number;
	points: number | null;
}

export interface IJoinedUser {
	first_name: string;
	id: number;
	last_name: string;
	username: string;
	country: string;
}

export interface IJoinToLeagueRequest {
	code: string;
}

export interface ILeaguesReducer {
	league: ILeague;
	leagues: ILeague[];
	nextPage: boolean;
	joinedCodes: {
		[code: string]: boolean;
	};
	create_league_process: boolean;
	update_league_process: boolean;
	league_updated: boolean;
	invites_sent: boolean;
	isJoined: boolean;
	isFetched: boolean;
}

export interface IProps {
	league: ReturnType<typeof getMyLeagues>[number];
	stageId?: number;
	stageNum?: number;
}

export type TLeaguesShowMyResponse = Pick<ILeaguesReducer, "nextPage" | "leagues">;
export type TLeagueCurrentResponse = {league: ILeague; isJoined: boolean};

export interface ILeagueId {
	id: number;
}

export interface ILeaveLeagueAction extends ILeagueId {
	code: string;
}
export interface IJoinFilter {
	limit?: number;
	page?: number;
	search?: string;
	raceId?: number;
}

export interface INextAction<T> {
	leagues: T[];
	readonly nextPage?: boolean;
}

export interface IStepProps {
	isVisible: boolean;
	onSubmit?: React.FormEvent<HTMLFormElement>;
}

export interface ICreateLeaguePayload {
	name: string;
	privacy: string;
	raceId: number;
	description?: string;
}
export interface IUpdateLeaguePayload {
	id: number;
	name?: string;
	privacy?: string;
	description?: string;
	raceId?: number | null;
}

export interface IInvitePayload {
	email: string;
	name: string;
}

export interface IInviteEmails {
	league_id: number;
	invites: IInvitePayload[];
}
