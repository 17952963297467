import * as React from "react";
import styled from "styled-components";
import {uniqueId} from "lodash";
import {IconTick} from "components/Icons/IconTick";
import {ErrorText} from "components/FormElements/index";

interface ICheckbox extends React.InputHTMLAttributes<HTMLInputElement> {
	readonly name: string;
	readonly id?: string;
	readonly tick_position?: string;
	readonly type?: string;
	readonly is_radio?: boolean;
	readonly width?: string;
	readonly errorText?: string;
}

const StyledCheckbox = styled.div`
	position: relative;
	display: flex;
	align-items: center;
`;

interface ICheckboxStyled {
	readonly tick_position?: string;
}

const CheckboxStyled = styled.input<ICheckboxStyled>`
	position: absolute;
	visibility: hidden;
	${({tick_position}) => tick_position}: 0;
	z-index: 0;
`;
const Radio = styled.div`
	width: 20px;
	height: 20px;
	background: #ffffff;
	border: 2px solid #603f99;
	border-radius: 2px;
	margin-right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 auto;
`;

interface ILabel extends ICheckboxStyled {
	readonly is_radio?: boolean;
	readonly width?: string;
}

const Label = styled.label`
	display: flex;
	transition: 0.4s;
	position: relative;
	text-align: left;
	max-width: ${({width}: ILabel) => width || "100%"};
	box-sizing: border-box;
	width: 100%;
	cursor: pointer;
	align-items: center;
	line-height: 16px;
	font-size: 12px;
	svg path {
		fill: transparent;
	}

	${CheckboxStyled}:disabled + & {
		cursor: default;
	}
	${CheckboxStyled}:checked + & {
		svg path {
			fill: #ffffff;
		}
	}
	${CheckboxStyled}:checked + & {
		${Radio} {
			background: #603f99;
		}
	}
	a {
		text-decoration: underline;
	}
`;

export const Checkbox: React.FC<ICheckbox> = ({
	onChange,
	children,
	tick_position = "left",
	type = "checkbox",
	id = uniqueId("checkbox"),
	width,
	errorText,
	...rest
}) => (
	<StyledCheckbox>
		<CheckboxStyled onChange={onChange} id={id} type={type} tick_position={tick_position} {...rest} />
		<Label htmlFor={id} is_radio={type === "radio"} tick_position={tick_position} width={width}>
			<Radio>
				<IconTick width={15} height={12} />
			</Radio>
			{children}
		</Label>
		{errorText ? <ErrorText>{errorText}</ErrorText> : null}
	</StyledCheckbox>
);
