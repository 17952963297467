import {createReducer} from "redux-act";

import {IDictionary} from "modules/types";
import {IRiderStats} from "modules/types/rider";
import {fetchRiderScoreSuccess} from "modules/actions/rider";
import {setSelectedRiderId} from "modules/actions";

const initialState: IDictionary<IRiderStats> = {};

export const riderScore = createReducer<IDictionary<IRiderStats>>({}, initialState);
riderScore.on(fetchRiderScoreSuccess, (state, payload) => payload);
riderScore.on(setSelectedRiderId, (state, payload) => (!payload ? initialState : state));
