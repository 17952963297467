import React from "react";
import styled from "styled-components";
import {BaseContainer} from "components/Layout";
import fanhubLogo from "assets/img/fanhub.svg";
import {media} from "assets/css/media";
import {useTranslation} from "react-i18next";

const Wrapper = styled.footer`
	height: 102px;
	background: #000000;
	color: #fff;
	display: flex;
	font-size: 12px;
	line-height: 15px;
	justify-content: center;
	@media (max-width: ${media.mobile}) {
		height: auto;
	}
`;
const InnerWrapper = styled.div`
	align-items: center;
	display: flex;
	width: 100%;
	@media (max-width: ${media.mobile}) {
		flex-direction: column;
		justify-content: center;
		padding: 20px 0;
		& > * {
			margin-bottom: 20px;
		}
	}
`;
const Copyright = styled.div`
	flex: 1;
`;
const LinksWrapper = styled.nav`
	display: flex;
	align-items: center;
`;
const Link = styled.a`
	text-decoration: underline;
	margin-right: 21px;
	color: #fff;
`;
const FanHubLogo = styled.img``;

export const Footer: React.FC = () => {
	const {t} = useTranslation();
	return (
		<Wrapper>
			<BaseContainer align={"center"} justify={"center"}>
				<InnerWrapper>
					<Copyright>{t("footer_copyright_text")}</Copyright>
					<LinksWrapper>
						<Link href={"https://www.velon.cc/terms-conditions"} target={"_blank"}>
							{t("footer_terms_text")}
						</Link>
						<Link href={"https://www.velon.cc/privacy-cookies"} target={"_blank"}>
							{t("footer_privacypolicy_text")}
						</Link>
					</LinksWrapper>
					<FanHubLogo src={fanhubLogo} />
				</InnerWrapper>
			</BaseContainer>
		</Wrapper>
	);
};
