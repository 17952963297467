import {SyntheticEvent} from "react";
import {IDictionary, IFormState} from "modules/types";
import {keys, get} from "lodash";

export const isFormStateValidationErrors = (state: IDictionary<IFormState>) => {
	let hasError = false;
	keys(state).forEach((value) => {
		const formState = state[value];
		if (formState.error && !hasError) {
			hasError = true;
		}
	});
	return hasError;
};

export const valueOfElement = (e: SyntheticEvent<HTMLInputElement | HTMLSelectElement>) => {
	const is_checkbox = get(e.currentTarget, "type") === "checkbox";
	const checkboxValue = get(e.currentTarget, "checked", 0) ? "true" : "false";
	return is_checkbox ? checkboxValue : e.currentTarget.value;
};

export const getFieldData = (
	e: SyntheticEvent<HTMLInputElement | HTMLSelectElement>,
	formState: IDictionary<IFormState>
) => {
	const value = valueOfElement(e);
	const name = e.currentTarget.name;
	const title = e.currentTarget.title;
	return {
		name,
		value,
		validations: formState[name]?.validations,
		errorsTexts: formState[name]?.errorsTexts,
		title,
	};
};
