import React from "react";
import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {media} from "assets/css/media";
import {useSelector} from "react-redux";
import {getIsUserAuthed} from "modules/selectors/user";
import {Exist} from "components/Exist";
import {useTranslation} from "react-i18next";
import {IS_PRIZES_HIDDEN} from "modules/constants";

interface IWrapper {
	marginLeft?: string;
}

const MenuWrapper = styled.nav<IWrapper>`
	display: flex;
	align-items: center;
	margin-left: ${(props) => props.marginLeft || "56px"};
	align-self: stretch;
`;
const MenuItem = styled(NavLink)`
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	padding: 0 9px;
	display: flex;
	align-self: stretch;
	align-items: center;
	margin-right: 20px;
	color: #252525;
	text-decoration: none;
	font-weight: 600;
	&.active {
		color: #603f99;
		border-bottom: 3px solid #603f99;
	}
	@media (max-width: ${media.mobile}) {
		display: none;
	}
`;

export const DesktopMenu: React.FC = () => {
	const {t} = useTranslation();
	const isAuthed = useSelector(getIsUserAuthed);
	return isAuthed ? (
		<MenuWrapper>
			<MenuItem to={"/team"}>{t("nav_header_team")}</MenuItem>
			<MenuItem to={"/leagues"}>{t("nav_header_leagues")}</MenuItem>
			<MenuItem to={"/rankings"}>{t("nav_header_ranking")}</MenuItem>
			{IS_PRIZES_HIDDEN ? null : <MenuItem to={"/prizes"}>{t("nav_header_prizes")}</MenuItem>}
		</MenuWrapper>
	) : null;
};

export const RightMenu: React.FC = () => {
	const isAuthed = useSelector(getIsUserAuthed);
	const {t} = useTranslation();
	return (
		<MenuWrapper marginLeft={"auto"}>
			<MenuItem to={"/help"}>{t("nav_header_help")}</MenuItem>
			<Exist when={isAuthed}>
				<MenuItem to={"/account"}>{t("nav_header_account")}</MenuItem>
			</Exist>
		</MenuWrapper>
	);
};
