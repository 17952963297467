import React from "react";
import styled from "styled-components";
import bannerDesktop from "assets/img/velon-desktop-ad-banner.jpg";
import bannerMobile from "assets/img/velon-mobile-ad-banner.jpg";
import {NavLink} from "react-router-dom";
import {IS_PRIZES_HIDDEN} from "modules/constants";

// export const AdvertRectangleWrapper = styled.div`
// 	width: 300px;
// 	height: 50px;
// 	margin: 20px auto;
// 	position: relative;
// 	overflow: hidden;
// 	background: url(${bannerMobile})no-repeat 50% 50%;
// 	background-size: contain;
// 	@media screen and (min-width: 768px) {
// 		width: 728px;
// 		height: 90px;
// 		background: url(${bannerDesktop})no-repeat 50% 50%;
// 		background-size: contain;
// 	}
// `;
const TempBannerLink = styled(NavLink)`
	width: 300px;
	height: 50px;
	margin: 20px auto;
	position: relative;
	overflow: hidden;
	display: block;
	background: url(${bannerMobile}) no-repeat 50% 50%;
	background-size: contain;
	@media screen and (min-width: 768px) {
		width: 728px;
		height: 90px;
		background: url(${bannerDesktop}) no-repeat 50% 50%;
		background-size: contain;
	}
`;

interface IProps {
	id: string;
}

export const AdvertRectangle: React.FC<IProps> = ({id}) => {
	// return <AdvertRectangleWrapper id={id} />;
	return IS_PRIZES_HIDDEN ? null : <TempBannerLink id={id} to={"/prizes"} />;
};
