import {call, put, select} from "typed-redux-saga";
import {Api} from "modules/utils/Api";
import {
	askUserToRecover,
	fillUserFormDataSuccess,
	setAuthedUser,
	setIsUserFetched,
	setRecoveredSuccess,
	setUserFormDataError,
	updateUserFormData,
} from "modules/actions/user";
import {clearAuthStore, setGlobalPreloaderState, globalError} from "modules/actions";
import {getAccountUserForm, getIsUserAuthed, getUserState} from "modules/selectors/user";
import {USER__DEFAULT_FORM_STATE} from "modules/constants/user";
import {chain, get, identity, mapValues} from "lodash";
import {IDictionary, IFormEventValue, IFormState, ISagaAction} from "modules/types";
import {Storage} from "modules/utils/Storage";
import {validateFieldStore} from "modules/utils/helpers/validations";
import {isFormStateValidationErrors} from "modules/utils/helpers/auth";

const updateUserFormStorage = (key: string, value: IDictionary<IFormState>) => {
	Storage.SET(key, value);
};

export const fetchUserSaga = function* () {
	try {
		yield* put(setGlobalPreloaderState(true));
		const {
			success: {user},
		} = yield* call(Api.User.get);
		yield* put(setAuthedUser(user));

		if (!user.recovered) {
			yield* put(askUserToRecover());
		}
	} catch (e) {
		const isAuthed = yield* select(getIsUserAuthed);

		if (isAuthed) {
			yield* put(globalError(e));
		}

		yield* put(setIsUserFetched());
	}
	yield* put(setGlobalPreloaderState(false));
};

export const requestUserRecoverSaga = function* () {
	yield* put(setGlobalPreloaderState(true));

	try {
		const {
			success: {user},
		} = yield* call(Api.User.recover);
		yield* put(setAuthedUser(user));
		yield* put(setRecoveredSuccess());
	} catch (e) {
		const isAuthed = yield* select(getIsUserAuthed);

		if (isAuthed) {
			yield* put(globalError(e));
		}

		yield* put(setIsUserFetched());
	}

	yield* put(setGlobalPreloaderState(false));
};

export const fillUserFormDataSaga = function* () {
	const user = yield* select(getUserState);

	const dataObject: IDictionary<IFormState> = mapValues(USER__DEFAULT_FORM_STATE, (value, key: string) => ({
		...value,
		value: get(user, key, "") as string,
	}));
	yield* put(fillUserFormDataSuccess(dataObject));
};

export const setUserFormDataSaga = function* ({payload}: ISagaAction<IFormEventValue>) {
	yield put(
		updateUserFormData({
			name: payload.name,
			value: payload.value,
		})
	);

	const userFormState = yield* select(getAccountUserForm);

	updateUserFormStorage("accountUserForm", userFormState);
};

export const validateUserFormDataSaga = function* ({payload}: ISagaAction<IFormEventValue>) {
	const userFormState = yield* select(getAccountUserForm);
	const validation = validateFieldStore(payload, userFormState);
	if (validation) {
		yield* put(
			setUserFormDataError({
				name: payload.name,
				error: validation.errorText,
			})
		);
	}
};

export const submitUserFormDataSaga = function* () {
	const accountUserFormState = yield* select(getAccountUserForm);
	const hasError = isFormStateValidationErrors(accountUserFormState);

	if (hasError) {
		return;
	}
	const sendData = chain(accountUserFormState)
		.omit("confirmEmail", "confirmPassword", "terms")
		.omitBy((item) => !identity(item.value))
		.mapValues((item, key) => {
			if (!item) {
				return null;
			}
			if (item.value === "true") {
				return true;
			}
			if (item && item.value === "false") {
				return false;
			}
			return item.value;
		})
		.value();

	try {
		yield* put(setGlobalPreloaderState(true));
		const {success} = yield* call(Api.User.update, sendData);
		yield put(setAuthedUser(success.user));

		yield put(clearAuthStore());
		Storage.REMOVE("registrationForm");
	} catch (e) {
		yield* put(globalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};
