import React, {SyntheticEvent} from "react";
import {Modal} from "components/Modal";
import styled from "styled-components";
import logo from "assets/img/velon-logo.svg";
import {useTranslation} from "react-i18next";
import {FormField, Input} from "components/FormElements";
import {useDispatch, useSelector} from "react-redux";
import {getResetPassOpen, getResetPassState} from "modules/selectors/auth";
import {setResetPassOpen, setResetPassValue, submitResetPassForm, validateResetPassValue} from "modules/actions";
import {Button} from "components/Buttons";
import {getFieldData} from "modules/utils/helpers/auth";

const Logo = styled.img`
	max-width: 154px;
	width: 100%;
	margin: 0 auto 20px;
	display: block;
`;
const Title = styled.div`
	font-size: 16px;
	font-weight: bold;
	line-height: 22px;
	text-align: center;
	margin-bottom: 6px;
`;
const Form = styled.form`
	display: flex;
	flex-direction: column;
	button {
		margin: 20px auto 0;
	}
`;

export const ResetPasswordModal: React.FC = () => {
	const dispatch = useDispatch();
	const resetPassForm = useSelector(getResetPassState);
	const isOpen = useSelector(getResetPassOpen);
	const {password, confirmPassword} = resetPassForm;
	const {t} = useTranslation();
	const isSubmitDisabled = Boolean(!password.value || password.error || !password.value || password.error);

	const setValue = (e: SyntheticEvent<HTMLInputElement>) => {
		dispatch(setResetPassValue(getFieldData(e, resetPassForm)));
	};
	const validateHandler = (e: SyntheticEvent<HTMLInputElement>) => {
		dispatch(validateResetPassValue(getFieldData(e, resetPassForm)));
	};
	const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		dispatch(submitResetPassForm());
	};

	const onCloseHandler = () => {
		dispatch(setResetPassOpen({isOpen: false, token: ""}));
	};
	return isOpen ? (
		<Modal onClose={onCloseHandler}>
			<Logo src={logo} />
			<Title>{t("forget_password_form_description2")}</Title>
			<Form onSubmit={submitForm}>
				<FormField>
					<Input
						name="password"
						type="password"
						label={t("forget_password_form_new_password")}
						placeholder={t("forget_password_form_new_password_filed")}
						onChange={setValue}
						onBlur={validateHandler}
						title={"Password"}
						defaultValue={password.value}
						errorText={password.error}
						required={true}
						showPassword={true}
					/>
				</FormField>
				<FormField>
					<Input
						name="confirmPassword"
						type="password"
						label={t("forget_password_form_new_password_confirmation")}
						placeholder={t("forget_password_form_new_password_confirmation_filed")}
						onChange={setValue}
						onBlur={validateHandler}
						title={"Password"}
						defaultValue={confirmPassword.value}
						errorText={confirmPassword.error}
						required={true}
						showPassword={true}
					/>
				</FormField>

				<Button type={"submit"} disabled={isSubmitDisabled}>
					{t("forget_password_form_cta3")}
				</Button>
			</Form>
		</Modal>
	) : null;
};
