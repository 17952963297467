import {IStore} from "modules/types";
import {createSelector} from "reselect";
import {find, chain, isEmpty, isEqual} from "lodash";
import {getLineup, getPoolFilters, getSelectedRaceId} from "modules/selectors/team";
import {RequestState} from "modules/constants/enums";

export const getRidersState = ({riders}: IStore) => riders.list;

export const isRidersReceived = ({riders}: IStore) =>
	isEqual(riders.requestState, RequestState.Received) && !isEmpty(riders.list);

export const isRidersRequested = ({riders}: IStore) => isEqual(riders.requestState, RequestState.Requested);

export const getRiderById = createSelector([getRidersState], (riders) => (riderId: number) =>
	find(riders, {id: riderId})
);

export const getRidersByFilter = createSelector(
	[getRidersState, getPoolFilters, getLineup, getSelectedRaceId],
	(riders, filter, lineup, selectedRaceId) => {
		return chain(riders)
			.filter((rider) => {
				const filterByPos = filter.position !== "all" ? rider.position === filter?.position : true;
				const filterBuTeam =
					filter.team > 0 ? rider.squad_id === Number(filter.team) : lineup.indexOf(rider.id) === -1;
				return filterByPos && filterBuTeam;
			})
			.filter((rider) => {
				if (filter.search.length < 1) {
					return true;
				}
				return (
					rider.first_name.toLocaleLowerCase().includes(filter.search) ||
					rider.last_name.toLocaleLowerCase().includes(filter.search)
				);
			})
			.sortBy((rider) => {
				if (!rider?.stats.races) {
					return false;
				}
				const statsRace = rider.stats.races[selectedRaceId];
				return statsRace?.overallRank || null;
			})
			.value();
	}
);
