import React from "react";
import styled from "styled-components";
import arrow_down from "assets/img/icons/arrow-down.svg";
import {ElementsWrapper, ErrorText, Label} from "./index";

const SelectBox = styled.select`
	border: 1px solid #ddd;
	box-sizing: border-box;
	padding: 0 20px;
	border-radius: 4px;
	display: inline-flex;
	font-size: 12px;
	height: 40px;
	appearance: none;
	line-height: 18px;
	background-image: url("${arrow_down}");
	background-repeat: no-repeat;
	background-position: right 5px center;
	background-size: 12px;
	background-color: #ffffff;

	&::-ms-expand {
		display: none;
	}

	&:disabled {
		opacity: 0.7;
		cursor: not-allowed;
	}
`;

interface IProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
	label?: string;
	errorText?: string;
}

export const Select: React.FC<IProps> = ({label, errorText, ...props}) => {
	return (
		<ElementsWrapper isDisabled={props.disabled} hasError={Boolean(errorText)}>
			{label ? <Label>{label}</Label> : null}
			<SelectBox {...props} />
			{errorText ? <ErrorText>{errorText}</ErrorText> : null}
		</ElementsWrapper>
	);
};
