import {SagaIterator} from "redux-saga";
import {call, put, select} from "typed-redux-saga";
import {
	clearLeague,
	fetchLeaguesForJoinSuccess,
	fetchMyLeagues,
	fetchMyLeaguesSuccess,
	fetchThisLeagueSuccess,
	joinToLeagueSuccess,
	postCreateLeagueSuccess,
	postInviteSuccess,
	postUpdateLeagueSuccess,
	rollBackJoinLeague,
	setGlobalPreloaderState,
	showGlobalError,
} from "modules/actions";
import {Api} from "modules/utils/Api";
import {
	ICreateLeaguePayload,
	IInviteEmails,
	IJoinFilter,
	IJoinToLeagueRequest,
	ILeagueId,
	ILeaveLeagueAction,
	ISagaAction,
	IUpdateLeaguePayload,
} from "modules/types";
import {omit, pickBy} from "lodash";
import {getLeaguesForJoin} from "modules/selectors/leagues";


export const getMyLeaguesSaga = function* (): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		const response = yield* call(Api.Leagues.show_my);
		if (response.success) {
			yield* put(
				fetchMyLeaguesSuccess({
					leagues: response.success.leagues,
					nextPage: response.success.nextPage,
				})
			);
		}
	} catch (e) {
		yield* put(showGlobalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const getLeaguesForJoinSaga = function* ({payload}: ISagaAction<IJoinFilter>): SagaIterator {
	try {
		const leagues = yield* select(getLeaguesForJoin);
		yield* put(setGlobalPreloaderState(true));
		const {success} = yield* call(Api.Leagues.show_for_join, pickBy(payload, Boolean));
		const leaguesToSave = payload.page ? [...leagues, ...success.leagues] : success.leagues;

		yield* put(
			fetchLeaguesForJoinSuccess({
				leagues: leaguesToSave,
				nextPage: success.nextPage,
			})
		);
	} catch (e) {
		yield* put(showGlobalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const postJoinToLeagueSaga = function* (action: ISagaAction<IJoinToLeagueRequest>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		yield* call(Api.Leagues.join_league, action.payload);
		yield* put(joinToLeagueSuccess(action.payload.code));
	} catch (e) {
		yield* put(showGlobalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};

// export const searchAndJoinSaga = function* (action: ISagaAction<IJoinToLeagueRequest>):SagaIterator {
// 	try {
// 		const query = get(action, "payload.code");
// 	}
// };

export const getCurrentLeague = function* (action: ISagaAction<ILeagueId>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		const {success} = yield* call(Api.Leagues.get_league, action.payload);

		yield* put(
			fetchThisLeagueSuccess({
				league: success.league,
				isJoined: success.isJoined,
			})
		);
	} catch (e) {
		yield* put(showGlobalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const postCreateLeagueSaga = function* ({payload}: ISagaAction<ICreateLeaguePayload>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		const response = yield* call(Api.Leagues.create_league, payload);
		yield* put(postCreateLeagueSuccess(response.success.league));
	} catch (e) {
		yield* put(showGlobalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const postUpdateLeagueSaga = function* ({payload}: ISagaAction<IUpdateLeaguePayload>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		const response = yield* call(Api.Leagues.update_league, payload);
		yield* put(postUpdateLeagueSuccess(response.success.league));
	} catch (e) {
		yield* put(showGlobalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const postInviteSaga = function* ({payload}: ISagaAction<IInviteEmails>): SagaIterator {
	try {
		const data = {
			...payload,
			invites: payload.invites.map((invite) => omit(invite, "error")),
		};
		yield* put(setGlobalPreloaderState(true));
		yield* call(Api.Leagues.invite, data);
		yield* put(postInviteSuccess());
	} catch (e) {
		yield* put(showGlobalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const postleaveLeagueSaga = function* ({payload}: ISagaAction<ILeaveLeagueAction>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));

		yield* call(Api.Leagues.leave_league, payload.id);
		yield* put(clearLeague());
		yield* put(fetchMyLeagues());
		yield* put(rollBackJoinLeague(payload.code));
	} catch (e) {
		yield* put(showGlobalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};
export const removeLeagueSaga = function* ({payload}: ISagaAction<ILeagueId>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		yield* call(Api.Leagues.remove_league, payload.id);
		yield* put(clearLeague());
	}
	catch (e){
		yield* put(showGlobalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};
