import {IStore} from "modules/types";
import {getFirstScheduledStage, getRaceById, getRacesState, getStageById} from "modules/selectors/races";
import {createSelector} from "reselect";
import {RaceStageStatusEnum, RaceStatusEnum} from "modules/constants/enums";
import {STAGE_TRADES_LIMIT} from "modules/constants";
import {find, findIndex} from "lodash";

export const getSelectedRaceId = ({team}: IStore) => team.selectedRaceId;
export const getSelectedStageId = ({team}: IStore) => team.selectedStageId;
export const getLineup = ({team}: IStore) => team.lineup;
export const getCaptainIDs = ({team: {captains}}: IStore) => captains;

export const getTeamMobileView = ({team}: IStore) => team.mobileView;
export const getPoolFilters = ({team}: IStore) => team.filter;

export const getCurrentTeam = ({team}: IStore) => team.currentTeam;
export const getIsTeamFetched = ({team}: IStore) => team.isTeamFetched;
export const getIsTeamSaved = ({team}: IStore) => team.isSaved;
export const getUserStats = ({team}: IStore) => team.userStats;

export const getSelectedRace = createSelector([getRacesState, getSelectedRaceId], (races, raceId) =>
	getRaceById(races, raceId)
);
export const getSelectedStage = createSelector([getSelectedRace, getSelectedStageId], (race, stageId) =>
	getStageById(race, stageId)
);
export const getIsSelectedStageLocked = createSelector(
	[getSelectedStage],
	(stage) => stage?.status !== RaceStageStatusEnum.Scheduled
);
export const getSelectedRiderId = ({team}: IStore) => team.selectedRiderId;
export const getLineupTradeIn = ({team}: IStore) => team.tradeIn;

export const getIsRiderInTradeIn = createSelector([getLineupTradeIn], (tradeInRiderId) => (riderId: number) =>
	tradeInRiderId === riderId
);

export const getIsTradeAvailable = createSelector(
	[getIsSelectedStageLocked, getCurrentTeam, getSelectedStage, getSelectedRace],
	// eslint-disable-next-line complexity
	(isLocked, team, selectedStage, race) => {
		const currentStageIndex = findIndex(race?.stages, {id: selectedStage?.id});
		const selectedStageNum = selectedStage?.stage || 0;
		const isTradeAvailable = team.stageNumber < selectedStageNum && currentStageIndex > 0;
		const stageLineUp = find(team.stages, {stageId: selectedStage?.id});
		const isNoTrades = stageLineUp?.trades.length === STAGE_TRADES_LIMIT;
		const hasTeam = Boolean(team.id);

		return {
			canSaveCaptain: hasTeam && !isLocked,
			isTradeAvailable: hasTeam && isTradeAvailable && !isLocked && !isNoTrades,
			isCanManage: (!hasTeam && !isLocked) || (!isTradeAvailable && !isLocked),
			isComplete: race?.status === RaceStatusEnum.Complete,
			lockTradesAfter: race?.settings.lock_trades_after || null,
			tradesSize: stageLineUp?.trades.length,
			isStageComplete: selectedStage?.status === RaceStageStatusEnum.Complete,
		};
	}
);

export const getPreviousStage = createSelector([getSelectedRace, getSelectedStageId], (race, selectedStageId) => {
	const currentIndex = findIndex(race?.stages, {id: selectedStageId});
	return race?.stages[currentIndex - 1];
});

export const getFirstStageFromSelectedRace = createSelector([getSelectedRace], (race) => getFirstScheduledStage(race));

export const getStageByNumber = createSelector([getSelectedRace], (race) => (stageNum: number) =>
	find(race?.stages, {stage: stageNum}) || {stage: 0, id: 0}
);
