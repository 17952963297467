import React from "react";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {clearGlobalError} from "modules/actions";
import {Modal} from "components/Modal";
import {getGlobalError} from "modules/selectors/global";

const ModalContentWrapper = styled.div``;

const Title = styled.div`
	font-size: 20px;
	padding-bottom: 16px;
	font-weight: 500;
	margin-bottom: 18px;
	text-align: center;
`;

const Text = styled.div`
	font-size: 18px;
	margin: 30px 0;
	text-align: center;
	line-height: 1.5;
`;

export const GlobalErrors: React.FC = () => {
	const dispatch = useDispatch();
	const {message} = useSelector(getGlobalError);

	const onCloseClick = () => {
		dispatch(clearGlobalError());
	};

	return message ? (
		<Modal onClose={onCloseClick}>
			<ModalContentWrapper onClick={onCloseClick}>
				<Title>Error</Title>
				<Text>{message}</Text>
			</ModalContentWrapper>
		</Modal>
	) : null;
};

export default GlobalErrors;
