import {createReducer} from "redux-act";
import {ITeamReducer} from "modules/types/reducers";
import {
	changeTeamView,
	clearFilter,
	clearLineUp,
	clearTeamStore,
	setCaptains,
	setLineup,
	setPoolFilterValue,
	setRaceTeam,
	setSelectedRaceId,
	setSelectedRiderId,
	setSelectedStageId,
	setTeamIsFetched,
	updateTradeIn,
} from "modules/actions/team";
import {IPoolFilter} from "modules/types/team";
import {clearUser} from "modules/actions";

const defaultState: ITeamReducer = {
	currentTeam: {
		id: 0,
		raceId: 0,
		userId: 0,
		points: 0,
		stageNumber: 0,
		stages: [],
	},
	lineup: [0, 0, 0, 0, 0, 0, 0, 0],
	captains: [],
	userStats: null,
	selectedRaceId: 0,
	selectedStageId: 0,
	mobileView: "team",
	filter: {
		position: "all",
		search: "",
		team: 0,
	},
	isTeamFetched: false,
	isSaved: false,
	selectedRiderId: 0,
	tradeIn: null,
};

const defaultFilter: IPoolFilter = {
	position: "all",
	search: "",
	team: 0,
};

export const team = createReducer<ITeamReducer>({}, defaultState);
team.on(setSelectedRaceId, (state, payload) => ({
	...state,
	selectedRaceId: payload,
}));
team.on(setSelectedStageId, (state, payload) => ({
	...state,
	selectedStageId: payload,
}));
team.on(changeTeamView, (state, payload) => ({
	...state,
	mobileView: payload,
}));
team.on(setLineup, (state, payload) => ({
	...state,
	lineup: payload,
	isSaved: false,
}));

team.on(setCaptains, (state, captains) => ({
	...state,
	captains,
	isSaved: false,
}));

team.on(clearLineUp, (state) => ({
	...state,
	lineup: [0, 0, 0, 0, 0, 0, 0, 0],
	captains: [],
	filter: defaultFilter,
	isSaved: false,
}));
team.on(setPoolFilterValue, (state, payload) => ({
	...state,
	filter: {
		...state.filter,
		...payload,
	},
}));
team.on(clearFilter, (state) => ({
	...state,
	filter: defaultFilter,
}));
team.on(setRaceTeam, (state, payload) => ({
	...state,
	...payload,
	isTeamFetched: true,
}));
team.on(setTeamIsFetched, (state, payload) => ({
	...state,
	isTeamFetched: payload,
}));
team.on(setSelectedRiderId, (state, payload) => ({
	...state,
	selectedRiderId: payload,
}));
team.on(updateTradeIn, (state, payload) => ({
	...state,
	tradeIn: payload,
}));
team.on(clearTeamStore, () => ({
	...defaultState,
}));
team.on(clearUser, () => ({
	...defaultState,
}));
