import {call, put, select, take} from "typed-redux-saga";
import {SagaIterator} from "redux-saga";
import {Api} from "modules/utils/Api";
import {ISagaAction} from "modules/types";
import {fetchRiderScoreSuccess} from "modules/actions/rider";
import {getSelectedRaceId} from "modules/selectors/team";
import {setSelectedRaceId} from "modules/actions";

export const fetchRiderScoreSaga = function* ({payload}: ISagaAction<number>): SagaIterator {
	try {
		let raceId = yield* select(getSelectedRaceId);
		if (!raceId) {
			const {payload} = yield* take(setSelectedRaceId);
			raceId = payload;
		}
		const scores = yield* call(Api.JSON.rider, payload, raceId);
		if (scores.code) {
			return;
		}
		yield* put(fetchRiderScoreSuccess(scores));
	} catch (e) {
		console.log(e);
	}
};
