import {call, put} from "typed-redux-saga";
import {Api} from "modules/utils/Api";
import {fetchSquadsSuccess} from "modules/actions";
import {SagaIterator} from "redux-saga";

export const fetchSquadsSaga = function* (): SagaIterator {
	try {
		const squads = yield* call(Api.JSON.squads);
		yield* put(fetchSquadsSuccess(squads));
	} catch (e) {
		console.log(e);
	}
};
