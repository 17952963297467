import {ElementsWrapper, ErrorText, IconType, Label} from "components/FormElements/index";
import React, {ForwardedRef} from "react";
import styled from "styled-components";

interface ITextAreaProps {
	onClick?: (e: React.SyntheticEvent<HTMLTextAreaElement>) => void;
	onChange?: (e: React.SyntheticEvent<HTMLTextAreaElement>) => void;
	icon_type?: IconType;
	label?: string;
	showPassword?: boolean;
	tooltipText?: string;
	errorText?: string;
	ref?: ForwardedRef<HTMLTextAreaElement>;
}

const TextareaWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-start;
	position: relative;
`;
const TextareaElement = styled.textarea`
	border: 1px solid #ccc;
	box-sizing: border-box;
	padding: 5px 10px;
	border-radius: 2px;
	display: inline-flex;
	height: 196px;
	max-width: 581px;
	width: 100%;
	background-color: #FAFAFA;
	color: #000;
	font-family: "Source Sans Pro", sans-serif;
	font-size: 14px;
	letter-spacing: 0;
	line-height: 18px;
	resize: none;
	text-transform: unset;
`;

type IProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & ITextAreaProps;

export const Textarea: React.ForwardRefRenderFunction<HTMLTextAreaElement, IProps> = ({label, showPassword, tooltipText = "", errorText, maxLength, className, ...props}) => {
	return (
		<ElementsWrapper className={className} isDisabled={props.disabled}>
			{label ? <Label>{label}</Label> : null}
			<TextareaWrapper>
				<TextareaElement {...props} />
			</TextareaWrapper>
			{errorText ? <ErrorText>{errorText}</ErrorText> : null}
		</ElementsWrapper>
	);
}