import styled, {css} from "styled-components";

/**
 * Label
 */
export const Label = styled.label`
	display: block;
	font-size: 14px;
	color: #2d2d2d;
	line-height: 26px;
	text-align: left;
	margin-bottom: 10px;
`;

export const ErrorText = styled.p`
	color: #e02020;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.5px;
	line-height: 18px;
	margin-top: 10px;
	position: absolute;
	top: 85%;
`;

interface IElementWrapperProps {
	isDisabled?: boolean;
	hasError?: boolean;
}

const getDisabledElementWrapper = ({isDisabled}: IElementWrapperProps) =>
	isDisabled &&
	css`
		> * {
			opacity: 0.7;
			cursor: not-allowed;
		}
	`;

const getErrorElementStyles = ({hasError}: IElementWrapperProps) =>
	hasError &&
	css`
		> input,
		select {
			border-color: #ff003a;
		}
	`;

export const ElementsWrapper = styled.div<IElementWrapperProps>`
	width: 100%;
	display: flex;
	justify-content: space-between;
	position: relative;
	flex-direction: column;
	> input,
	select,
	textarea {
		width: 100%;
	}
	${getDisabledElementWrapper};
	${getErrorElementStyles};
`;

/**
 * Checkbox
 */
export * from "./Checkbox";

/**
 * Select
 */
export * from "./Select";

/**
 * Input
 */
export * from "./Input";

export * from "./FormField";

export * from "./RadioButton";
export * from "./Switch";
export * from "./FormTitle";
export * from "./Button";
export * from "./Textarea";
