import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import styled from "styled-components";
import {Button} from "components/Buttons";
import bg from "assets/img/bg_v2.png";
import {useTranslation} from "react-i18next";
import {motion} from "framer-motion";
import {routeLayoutsAnimations} from "modules/utils/helpers/dom";

const Wrapper = styled(motion.div)`
	padding: 50px 0;
	text-align: center;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: linear-gradient(180deg, rgba(96, 63, 153, 0) 0%, #603f99 100%);
	background-image: url(${bg});
	background-size: cover;
`;

const NotFoundWrapper = styled.div`
	border-top: 8px solid #fff;
	border-bottom: 8px solid #fff;
	padding: 10px 0 20px;
	width: 210px;
	margin: 0 auto;
	text-align: center;
	color: #fff;
	span {
		font-size: 7em;
	}
	p {
		font-size: 1.5em;
	}
`;

const Title = styled.div`
	font-size: 16px;
	text-align: center;
	padding: 1em;
	color: #fff;
`;
const TakMeHomeButton = styled(Button)`
	max-width: 200px;
`;

export const NotFound: React.FC<RouteComponentProps> = ({history}) => {
	const {t} = useTranslation();
	const gotToHome = () => history.push("/");
	return (
		<Wrapper initial="initial" animate="in" exit="out" variants={routeLayoutsAnimations}>
			<NotFoundWrapper>
				<span>404</span>
				<p>{t("notfound_title_404")}</p>
			</NotFoundWrapper>
			<Title>{t("notfound_description_404")}</Title>
			<TakMeHomeButton onClick={gotToHome}>{t("notfound_cta_404")}</TakMeHomeButton>
		</Wrapper>
	);
};

export default withRouter(NotFound);
