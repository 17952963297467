import {HTTPClient as HTTPClientCore, IRequestConfig} from "@fanhubmedia/fe-common-utils";
import axios from "axios";
import {CANCEL} from "redux-saga";
import {
	IApiResponse,
	IAuthResponse,
	ICreateAutoPick,
	ICreateLeaguePayload,
	IDictionary,
	IGetRaceTeamResponse,
	IInviteEmails,
	IJoinFilter,
	IJoinToLeagueRequest,
	ILeague,
	ILeagueId,
	ISaveTeam,
	ITradeParams,
	IUpdateAutoPick,
	IUpdateLeaguePayload,
	IUpdateLineup,
	TLeagueCurrentResponse,
	TLeaguesShowMyResponse,
} from "modules/types";
import i18n from "i18next";
import {TSquadsReducer} from "modules/types/squads";
import {TRacesReducer} from "modules/types/races";
import ApiError from "modules/utils/Api/ApiError";
import {IRider} from "modules/types/riders";
import {IContactData, IFaqItem, IHelpPages} from "modules/types/help";
import {IUser} from "modules/types/user";
import {ILeagueRankFilter, IRankingFilter, IRankingFilterRace, TRankingByAllResponce} from "modules/types/rankings";
import {IRiderStats} from "modules/types/rider";

interface INewConfig extends IRequestConfig {
	params: unknown;
}

export class HTTPClient extends HTTPClientCore {
	/**
	 * Overridden method adds CancelToken symbol, that allow redux-saga'
	 * "takeLatest" function to cancel any requests automatically.
	 */
	public makeRequest<T = never>(config: IRequestConfig): Promise<T> {
		const source = axios.CancelToken.source();

		const new_config: INewConfig = {
			...config,
			params: {
				...config.params,
				// sid: 'ef740d410c09396157aa6462_1617695285'
			},
			cancelToken: source.token,
		};

		const request = super.makeRequest(new_config);

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		request[CANCEL] = () => source.cancel();

		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return request;
	}
}

const JSONClient = new HTTPClient({
	baseURL: process.env.REACT_APP_JSON_URL || "",
});

const APIClient = new HTTPClient({
	baseURL: process.env.REACT_APP_API_URL || "",
	withCredentials: true,
	onCatchNetworkError: (err) => {
		const responseData = err?.response?.data as IApiResponse;
		ApiError.CHECK(responseData, err?.response?.status);
	},
});

const getLocalizationURL = (url: string) => {
	return `${i18n.language}/${url}`;
};

const getStaticPageURL = (url: string) => {
	return `${url}/${i18n.language}.json`;
};

export const Api = {
	JSON: {
		squads: () => JSONClient.get<TSquadsReducer>("squads.json"),
		races: () => JSONClient.get<TRacesReducer>("races.json"),
		riders: (raceID: number) => JSONClient.get<IRider[]>(`riders/${raceID}.json`),
		rider: (riderID: number, raceID: number) =>
			JSONClient.get<IDictionary<IRiderStats>>(`rider_scores/${raceID}/${riderID}.json`),
		countries: () => JSONClient.get<IDictionary<string>>("countries.json"),
	},
	Auth: {
		login: (params: IDictionary<unknown>) =>
			APIClient.post<IApiResponse<IAuthResponse>>(getLocalizationURL("auth/login"), params),
		logout: () => APIClient.post<IApiResponse>(getLocalizationURL("auth/logout")),
		register: (params: IDictionary<string | boolean | null>) =>
			APIClient.post<IApiResponse<IAuthResponse>>(getLocalizationURL("auth/register"), params),
		deactivate_account: () => APIClient.post<IApiResponse>(getLocalizationURL("auth/deactivate_account")),
		resetPasswordRequest: (params: IDictionary<string>) =>
			APIClient.post<IApiResponse>(getLocalizationURL("auth/password_reset/request"), params),
		resetPassword: (params: IDictionary<string>) =>
			APIClient.post<IApiResponse>(getLocalizationURL("auth/password_reset"), params),
		registerAllowed: () =>
			APIClient.get<IApiResponse<{isAllowed: boolean}>>(getLocalizationURL("auth/registration_allowed")),
	},
	User: {
		get: () => APIClient.get<IApiResponse<IAuthResponse>>(getLocalizationURL("user")),
		recover: () => APIClient.get<IApiResponse<IAuthResponse>>(getLocalizationURL("user/recover")),
		update: (params: Partial<IUser>) =>
			APIClient.post<IApiResponse<IAuthResponse>>(getLocalizationURL("user"), params),
	},
	Leagues: {
		show_my: () => APIClient.get<IApiResponse<TLeaguesShowMyResponse>>(getLocalizationURL("league/show-my")),
		show_for_join: (params: IJoinFilter) =>
			APIClient.get<IApiResponse<TLeaguesShowMyResponse>>(getLocalizationURL("league/show-for-join"), params),
		join_league: ({code}: IJoinToLeagueRequest) =>
			APIClient.post<ILeague>(getLocalizationURL(`league/${code}/join`)),
		get_league: ({id}: ILeagueId) =>
			APIClient.get<IApiResponse<TLeagueCurrentResponse>>(getLocalizationURL(`league/${id}`)),
		create_league: (params: ICreateLeaguePayload) =>
			APIClient.post<IApiResponse<TLeagueCurrentResponse>>(getLocalizationURL("league"), params),
		update_league: ({id, ...params}: IUpdateLeaguePayload) =>
			APIClient.post<IApiResponse<TLeagueCurrentResponse>>(getLocalizationURL(`league/${id}`), params),
		invite: ({league_id, ...params}: IInviteEmails) =>
			APIClient.post<IApiResponse>(getLocalizationURL(`league/${league_id}/invite`), params),
		leave_league: (id: number) => APIClient.post<IApiResponse>(getLocalizationURL(`league/${id}/leave`)),
		remove_league: (id: number) => APIClient.post<IApiResponse>(getLocalizationURL(`league/${id}/delete`)),
	},
	Team: {
		saveTeam: (params: ISaveTeam) =>
			APIClient.post<IApiResponse<IGetRaceTeamResponse>>(getLocalizationURL(`team`), params),
		saveCaptain: ({teamID, captains}: {teamID: number; captains: number[]}) =>
			APIClient.post<IApiResponse<IGetRaceTeamResponse>>(getLocalizationURL(`team/${teamID}/captain`), {
				captains,
			}),
		updateLineup: (teamId: number, params: IUpdateLineup) =>
			APIClient.post<IApiResponse<Pick<IGetRaceTeamResponse, "team">>>(
				getLocalizationURL(`team/${teamId}/lineup`),
				params
			),
		getRaceTeam: (raceId: number) =>
			APIClient.get<IApiResponse<IGetRaceTeamResponse>>(getLocalizationURL(`team/race/${raceId}`)),
		createAutoPick: (params: ICreateAutoPick) =>
			APIClient.post<IApiResponse<IGetRaceTeamResponse>>(getLocalizationURL(`team/autopick`), params),
		updateAutoPick: ({teamId, ...params}: IUpdateAutoPick) =>
			APIClient.post<IApiResponse<IGetRaceTeamResponse>>(getLocalizationURL(`team/${teamId}/autopick`), params),
		trade: (teamId: number, params: ITradeParams) =>
			APIClient.post<IApiResponse<IGetRaceTeamResponse>>(getLocalizationURL(`team/${teamId}/trade`), params),
		rollBackTrades: (teamId: number) =>
			APIClient.post<IApiResponse<IGetRaceTeamResponse>>(getLocalizationURL(`team/${teamId}/trade/rollback`)),
	},
	Help: {
		contact: (params: IContactData) => APIClient.post<IApiResponse>(getLocalizationURL(`contact`), params),
		faqs: () => JSONClient.get<IFaqItem[]>(getStaticPageURL("faqs")),
		how_to_play: () => JSONClient.get<IHelpPages>(getStaticPageURL("help_pages")),
	},
	Rankings: {
		overall_rankings: (params: IRankingFilter) =>
			APIClient.get<IApiResponse<TRankingByAllResponce>>(getLocalizationURL("rankings"), params),
		overall_rankings_race: ({race_id, ...params}: IRankingFilterRace) =>
			APIClient.get<IApiResponse<TRankingByAllResponce>>(getLocalizationURL(`rankings/${race_id}`), params),
		league_rankings: ({league_id, ...params}: ILeagueRankFilter) =>
			APIClient.get<IApiResponse<TRankingByAllResponce>>(
				getLocalizationURL(`league/${league_id}/rankings`),
				params
			),
		league_rankings_race: ({league_id, race_id, ...params}: ILeagueRankFilter) =>
			APIClient.get<IApiResponse<TRankingByAllResponce>>(
				getLocalizationURL(`league/${league_id}/rankings/${String(race_id)}`),
				params
			),
	},
};

export * from "modules/utils/Api/ApiError";
