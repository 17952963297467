import {Storage} from "modules/utils/Storage";
import {IUser} from "modules/types/user";
import {USER_DEFAULT_STATE} from "modules/constants/user";

export abstract class User {
	public static SAVE(user: IUser): void {
		Storage.SET("user", user);
		Storage.SET("isAuthorized", "true");
	}

	public static CLEAR(keys: string[] = []): void {
		["user", "isAuthorized"].forEach((value) => Storage.REMOVE(value));
	}

	public static GET(): IUser | Record<string, never> {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return JSON.parse(Storage.GET("user") || JSON.stringify(USER_DEFAULT_STATE));
	}

	public static IS_AUTHENTICATED(): boolean {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return JSON.parse(Storage.GET("isAuthorized") || "false");
	}
}
