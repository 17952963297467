import {IContactData, IFaqItem, IHelpPages} from "modules/types/help";
import {createAction} from "redux-act";

export const postContactForm = createAction<IContactData>("postContactForm");
export const postContactFormSuccess = createAction<boolean>("postContactFormSuccess");

export const fetchFaqs = createAction();
export const fetchFaqsSuccess = createAction<IFaqItem[]>();

export const fetchHelps = createAction();
export const fetchHelpsSuccess = createAction<IHelpPages>();
