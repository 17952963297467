import {createAction} from "redux-act";
import {IFormEventValue} from "modules/types";

export const setLoginValue = createAction<IFormEventValue>();
export const setLoginError = createAction<{[x: string]: string}>();
export const updateLoginValues = createAction<{[x: string]: string}>();
export const validateLoginValue = createAction<IFormEventValue>();
export const clearLoginFieldError = createAction<string>();
export const submitLoginForm = createAction();

export const setRegisterValue = createAction<IFormEventValue>();
export const setRegisterError = createAction<{[x: string]: string}>();
export const updateRegisterValues = createAction<{[x: string]: string}>();
export const validateRegisterValue = createAction<IFormEventValue>();
export const clearRegisterFieldError = createAction<string>();
export const submitRegisterForm = createAction<number>();
export const setRegistrationStep = createAction<1 | 2>();

export const setForgotPassOpen = createAction<boolean>();
export const setForgotPassValue = createAction<IFormEventValue>();
export const setForgotPassError = createAction<{[x: string]: string}>();
export const updateForgotPassValues = createAction<{[x: string]: string}>();
export const validateForgotPassValue = createAction<IFormEventValue>();
export const clearForgotPassFieldError = createAction<string>();
export const submitForgotPassForm = createAction();
export const setIsForgotEmailSent = createAction<boolean>();

export const setResetPassOpen = createAction<{isOpen: boolean; token: string}>();
export const setResetPassValue = createAction<IFormEventValue>();
export const setResetPassError = createAction<{[x: string]: string}>();
export const updateResetPassValues = createAction<{[x: string]: string}>();
export const validateResetPassValue = createAction<IFormEventValue>();
export const clearResetPassFieldError = createAction<string>();
export const submitResetPassForm = createAction();
export const setIsResetConfirmOpen = createAction<boolean>();

export const removeAccount = createAction("removeAccount");

export const userLogout = createAction();
export const clearAuthStore = createAction();

export const fetchRegistrationAllowed = createAction();
