import {createAction} from "redux-act";
import {IPoolFilter} from "modules/types/team";
import {ITeamReducer} from "modules/types/reducers";

export const setSelectedRaceId = createAction<number>();
export const setSelectedStageId = createAction<number>();
export const setSelectedStage = createAction<number>();
export const changeSelectedRaceId = createAction<number>();
export const changeTeamView = createAction<"team" | "pool">();
export const setLineup = createAction<number[]>();
export const setCaptains = createAction<number[]>();
export const fetchGameData = createAction();
export const clearLineUp = createAction();
export const setPoolFilterValue = createAction<Partial<IPoolFilter>>();
export const clearFilter = createAction();
export const autoFill = createAction();
export const createAutoPick = createAction();
export const updateAutoPick = createAction();
export const saveTeam = createAction();
export const updateLineup = createAction();
export const getTeamByRaceId = createAction();
export const setTeamIsFetched = createAction<boolean>();
export const setRaceTeam = createAction<Partial<ITeamReducer>>();
export const saveNewCaptains = createAction<number[]>();
export const setSelectedRiderId = createAction<number>();
export const addRiderToTransfer = createAction<number>();
export const updateTradeIn = createAction<number | null>();
export const rollBackTrade = createAction();
export const clearTeamStore = createAction();
