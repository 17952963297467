import {createReducer} from "redux-act";
import {IGlobalReducer} from "modules/types/reducers";
import {postContactFormSuccess, setGlobalPreloaderState, setRegistrationAllowed} from "modules/actions";

const defaultState: IGlobalReducer = {
	isShowGlobalPreloader: false,
	isContactSent: false,
	iaRegistrationAllowed: true,
	iaRegistrationAllowedFetched: false,
};

export const global = createReducer<IGlobalReducer>({}, defaultState);
global.on(setGlobalPreloaderState, (state, payload) => ({
	...state,
	isShowGlobalPreloader: payload,
}));
global.on(postContactFormSuccess, (state, payload) => ({
	...state,
	isContactSent: payload,
}));
global.on(setRegistrationAllowed, (state, payload) => ({
	...state,
	iaRegistrationAllowed: payload,
	iaRegistrationAllowedFetched: true,
}));
