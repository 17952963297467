import {createReducer} from "redux-act";
import {
	FORGOT_PASS_DEFAULT_FORM_STATE,
	LOGIN__DEFAULT_FORM_STATE,
	REGISTER__DEFAULT_FORM_STATE,
	RESET_PASS_DEFAULT_FORM_STATE,
} from "modules/constants/auth";
import {IAuthReducer} from "modules/types/reducers";
import {
	clearAuthStore,
	clearForgotPassFieldError,
	clearLoginFieldError,
	clearRegisterFieldError,
	clearResetPassFieldError,
	setForgotPassError,
	setForgotPassOpen,
	setIsForgotEmailSent,
	setIsResetConfirmOpen,
	setLoginError,
	setRegisterError,
	setRegistrationStep,
	setResetPassError,
	setResetPassOpen,
	updateForgotPassValues,
	updateLoginValues,
	updateRegisterValues,
	updateResetPassValues,
} from "modules/actions";
import {Storage} from "modules/utils/Storage";
import {IDictionary, IFormState} from "modules/types";

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const registerStorageState: IDictionary<IFormState> = JSON.parse(Storage.GET("registrationForm") || "{}");

const defaultState: IAuthReducer = {
	loginForm: LOGIN__DEFAULT_FORM_STATE,
	registrationForm: {
		...REGISTER__DEFAULT_FORM_STATE,
		...registerStorageState,
	},
	forgotPassForm: FORGOT_PASS_DEFAULT_FORM_STATE,
	resetPassForm: RESET_PASS_DEFAULT_FORM_STATE,
	isForgotPassOpen: false,
	isForgotEmailSent: false,
	isResetPassOpen: false,
	isResetConfirmOpen: false,
	resetPassToken: "",
	registrationStep: 1,
};

export const auth = createReducer<IAuthReducer>({}, defaultState);
auth.on(setLoginError, (state, payload) => ({
	...state,
	loginForm: {
		...state.loginForm,
		[payload.name]: {
			...state.loginForm[payload.name],
			error: payload.error,
		},
	},
}));
auth.on(clearLoginFieldError, (state, payload) => ({
	...state,
	loginForm: {
		...state.loginForm,
		[payload]: {
			...state.loginForm[payload],
			error: "",
		},
	},
}));
auth.on(updateLoginValues, (state, payload) => ({
	...state,
	loginForm: {
		...state.loginForm,
		[payload.name]: {
			...state.loginForm[payload.name],
			value: payload.value,
			error: "",
		},
	},
}));
auth.on(setRegisterError, (state, payload) => ({
	...state,
	registrationForm: {
		...state.registrationForm,
		[payload.name]: {
			...state.registrationForm[payload.name],
			error: payload.error,
		},
	},
}));
auth.on(clearRegisterFieldError, (state, payload) => ({
	...state,
	registrationForm: {
		...state.registrationForm,
		[payload]: {
			...state.registrationForm[payload],
			error: "",
		},
	},
}));
auth.on(updateRegisterValues, (state, payload) => ({
	...state,
	registrationForm: {
		...state.registrationForm,
		[payload.name]: {
			...state.registrationForm[payload.name],
			value: payload.value,
			error: "",
		},
	},
}));
auth.on(setRegistrationStep, (state, payload) => ({
	...state,
	registrationStep: payload,
}));
auth.on(setForgotPassOpen, (state, payload) => ({
	...state,
	isForgotPassOpen: payload,
}));
auth.on(setIsForgotEmailSent, (state, payload) => ({
	...state,
	isForgotEmailSent: payload,
}));
auth.on(setForgotPassError, (state, payload) => ({
	...state,
	forgotPassForm: {
		...state.forgotPassForm,
		[payload.name]: {
			...state.forgotPassForm[payload.name],
			error: payload.error,
		},
	},
}));
auth.on(clearForgotPassFieldError, (state, payload) => ({
	...state,
	forgotPassForm: {
		...state.forgotPassForm,
		[payload]: {
			...state.forgotPassForm[payload],
			error: "",
		},
	},
}));
auth.on(updateForgotPassValues, (state, payload) => ({
	...state,
	forgotPassForm: {
		...state.forgotPassForm,
		[payload.name]: {
			...state.forgotPassForm[payload.name],
			value: payload.value,
			error: "",
		},
	},
}));
auth.on(setResetPassOpen, (state, payload) => ({
	...state,
	isResetPassOpen: payload.isOpen,

	resetPassToken: payload.token,
}));
auth.on(setIsResetConfirmOpen, (state, payload) => ({
	...state,
	isResetConfirmOpen: payload,
}));
auth.on(setResetPassError, (state, payload) => ({
	...state,
	resetPassForm: {
		...state.resetPassForm,
		[payload.name]: {
			...state.resetPassForm[payload.name],
			error: payload.error,
		},
	},
}));
auth.on(clearResetPassFieldError, (state, payload) => ({
	...state,
	resetPassForm: {
		...state.resetPassForm,
		[payload]: {
			...state.resetPassForm[payload],
			error: "",
		},
	},
}));
auth.on(updateResetPassValues, (state, payload) => ({
	...state,
	resetPassForm: {
		...state.resetPassForm,
		[payload.name]: {
			...state.resetPassForm[payload.name],
			value: payload.value,
			error: "",
		},
	},
}));

auth.on(clearAuthStore, () => ({...defaultState}));
