import {createAction} from "redux-act";
import {ILeagueRankFilter, IRankingFilter, IRankingFilterRace, TRankingByAllResponce} from "modules/types/rankings";

export const fetchOverallRankingsAll = createAction<IRankingFilter>("fetchOverallRankings");
export const fetchOverallRankingsRace = createAction<IRankingFilterRace>("fetchOverallRankingsRace");
export const fetchOverallRankingsSuccess = createAction<TRankingByAllResponce>("fetchOverallRankings");

export const fetchLeagueRankingsAll = createAction<ILeagueRankFilter>();
export const fetchLeagueRankingsRace = createAction<ILeagueRankFilter>();
export const fetchLeagueRankingsSuccess = createAction<TRankingByAllResponce>();

export const clearRankings = createAction();
