import React from "react";
import styled, {css} from "styled-components";
import {NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {motion, AnimatePresence} from "framer-motion";
import {getIsUserAuthed} from "modules/selectors/user";
import {useTranslation} from "react-i18next";
import {userLogout} from "modules/actions";
import {Exist} from "components/Exist";
import {MobileLanguageSwitcher} from "components/LanguageSwitcher";
import {IS_PRIZES_HIDDEN} from "modules/constants";

const MenuList = styled(motion.div)`
	display: flex;
	flex-direction: column;
	padding: 0 39px;
	margin-top: 120px;
	position: absolute;
	right: 0;
	max-width: 100%;
	width: 300px;
	z-index: 10;
`;

const MenuItem = styled(motion.div)`
	margin-bottom: 50px;
`;

const LinkStyles = css`
	color: #fff;
	margin-bottom: 50px;
	font-size: 24px;
	text-decoration: none;
	font-weight: bold;
	&.active,
	&:hover {
		color: #fff;
	}
`;

const MenuLink = styled(NavLink)`
	${LinkStyles};
`;

// const ExternalMenuLink = styled.a`
// 	${LinkStyles};
// `;

const MenuButton = styled.button`
	${LinkStyles};
	background: none;
	border: none;
	text-align: left;
	padding: 0;
	cursor: pointer;
`;

const variants = {
	open: {
		transition: {staggerChildren: 0.07, delayChildren: 0.2},
	},
	closed: {
		transition: {staggerChildren: 0.05, staggerDirection: -1},
	},
};

const variantsItem = {
	open: {
		y: 0,
		opacity: 1,
		transition: {
			y: {stiffness: 1000, velocity: -100},
		},
	},
	closed: {
		y: 50,
		opacity: 0,
		transition: {
			y: {stiffness: 1000},
		},
	},
};

const Items = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const isLoggedIn = useSelector(getIsUserAuthed);
	const logoutHandler = () => {
		dispatch(userLogout());
	};
	return (
		<MenuList variants={variants}>
			<Exist when={isLoggedIn}>
				<MenuItem variants={variantsItem} whileHover={{scale: 1.1}} whileTap={{scale: 0.95}}>
					<MenuLink exact={true} to={"/"}>
						{t("nav_header_team")}
					</MenuLink>
				</MenuItem>
				<MenuItem variants={variantsItem} whileHover={{scale: 1.1}} whileTap={{scale: 0.95}}>
					<MenuLink to={"/leagues"}>{t("nav_header_leagues")}</MenuLink>
				</MenuItem>
				<MenuItem variants={variantsItem} whileHover={{scale: 1.1}} whileTap={{scale: 0.95}}>
					<MenuLink to={"/rankings"}>{t("nav_header_ranking")}</MenuLink>
				</MenuItem>
			</Exist>
			{IS_PRIZES_HIDDEN ? null : (
				<MenuItem variants={variantsItem} whileHover={{scale: 1.1}} whileTap={{scale: 0.95}}>
					<MenuLink to={"/prizes"}>{t("nav_header_prizes")}</MenuLink>
				</MenuItem>
			)}
			<MenuItem variants={variantsItem} whileHover={{scale: 1.1}} whileTap={{scale: 0.95}}>
				<MenuLink to={"/help"}>{t("nav_header_help")}</MenuLink>
			</MenuItem>
			<Exist when={isLoggedIn}>
				<MenuItem variants={variantsItem} whileHover={{scale: 1.1}} whileTap={{scale: 0.95}}>
					<MenuLink to={"/account"}>{t("nav_header_account")}</MenuLink>
				</MenuItem>
			</Exist>
			<MenuItem variants={variantsItem} whileHover={{scale: 1.1}} whileTap={{scale: 0.95}}>
				{!isLoggedIn ? (
					<MenuLink to={"/"}>{t("nav_mobile_login")}</MenuLink>
				) : (
					<MenuButton onClick={logoutHandler}>{t("nav_mobile_logout")}</MenuButton>
				)}
			</MenuItem>
			<MobileLanguageSwitcher />
		</MenuList>
	);
};

interface IProps {
	isOpen: boolean;
}
export const MobileMenu: React.FC<IProps> = ({isOpen}) => {
	return (
		<AnimatePresence initial={true}>
			{isOpen ? (
				<motion.div
					exit={{
						display: "none",
						transition: {delay: 1},
					}}>
					<Items />
				</motion.div>
			) : null}
		</AnimatePresence>
	);
};
