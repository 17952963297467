import {IDictionary, IFormState} from "modules/types";

export const USER_DEFAULT_STATE = {
	id: 0,
	firstName: "",
	lastName: "",
	email: "",
	birthday: "",
	username: "",
	heardAboutGameFrom: "",
	sponsorOptIn: true,
	disabledNotifications: false,
	favoriteRaceId: 0,
	favoriteRider: "",
	favoriteSquadId: 0,
	recovered: false,
};

const validation1 = "required, max40";

export const USER__DEFAULT_FORM_STATE: IDictionary<IFormState> = {
	firstName: {
		value: "",
		error: "",
		validations: validation1,
		errorsTexts: {
			required: "register_form_first_name_error",
		},
	},
	lastName: {
		value: "",
		error: "",
		validations: validation1,
		errorsTexts: {
			required: "register_form_first_name_error",
		},
	},
	email: {
		value: "",
		error: "",
		validations: "required, emailFormat",
		errorsTexts: {
			required: "register_form_email_error",
		},
	},
	birthday: {
		value: "",
		error: "",
		validations: "required",
		errorsTexts: {
			required: "register_form_dob_error",
		},
	},
	username: {
		value: "",
		error: "",
		validations: "required, max40",
		errorsTexts: {
			required: "registration_form_team_name_error",
		},
	},
	favoriteSquadId: {
		value: "",
		error: "",
		validations: "required",
		errorsTexts: {
			required: "myaccount_form_favourite_team_error",
		},
	},
	disabledNotifications: {
		value: "false",
		error: "",
	},
	favoriteRider: {
		value: "",
		error: "",
	},
	favoriteRaceId: {
		value: "",
		error: "",
	},
};
