import styled from "styled-components";

export const FormTitle = styled.h3`
	font-size: 26px;

	font-weight: bold;
	line-height: 36px;
	color: #010101;
	margin-bottom: 10px;
	position: relative;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	@media (max-width: 639px) {
		font-size: 18px;
		line-height: 27px;
		margin-bottom: 10px;
	}
`;