import {Api} from "modules/utils/Api";
import {SagaIterator} from "redux-saga";
import {ISagaAction} from "modules/types";
import {call, delay, put} from "typed-redux-saga";
import {fetchFaqsSuccess, fetchHelpsSuccess, postContactFormSuccess, setGlobalPreloaderState, showGlobalError} from "modules/actions";
import {IContactData} from "modules/types/help";

export const postContactFormSaga = function* ({payload}: ISagaAction<IContactData>): SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		yield* call(Api.Help.contact, payload);
		yield* put(postContactFormSuccess(true));
		yield* delay(3000);
		yield* put(postContactFormSuccess(false));
	} catch (e) {
		yield* put(showGlobalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const fetchFaqsSaga = function* ():SagaIterator {
	try {
		yield* put(setGlobalPreloaderState(true));
		const response = yield* call(Api.Help.faqs);
		yield* put(fetchFaqsSuccess(response));
	}
	catch (e){
		yield* put(showGlobalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};

export const fetchHelpsSaga = function* ():SagaIterator{
	try {
		yield* put(setGlobalPreloaderState(true));
		const response = yield* call(Api.Help.how_to_play);
		yield* put(fetchHelpsSuccess(response));
	}
	catch (e){
		yield* put(showGlobalError(e));
	}
	yield* put(setGlobalPreloaderState(false));
};