import * as React from "react";
import styled from "styled-components";

interface ISwitch extends React.AllHTMLAttributes<HTMLInputElement> {
	// readonly onChange: (e: React.SyntheticEvent<EventTarget>) => void;
	readonly SwitchLabel: string;
	readonly width?: string;
}

interface ILabel {
	width?: string;
}

const Label = styled.label`
	display: flex;
	transition: 0.4s;
	position: relative;
	text-align: left;
	max-width: ${({width}: ILabel) => width || "100%"};
	box-sizing: border-box;
	width: 100%;
	color: #555555;
`;

const StyledSwitch = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 29px 0;
	align-items: center;
`;
const Checkbox = styled.input`
	position: absolute;
	z-index: 1;
	opacity: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;

	&:checked + div {
		background: #603f99;
		&:after {
			left: 22px;
		}
	}
`;

const SwitchOuter = styled.div`
	position: relative;
	outline: none;
	cursor: pointer;
`;
const SwitchInner = styled.div<IToggle>`
	height: 18px;
	width: 43px;
	position: relative;
	background-color: ${(props) => (props.isToggle ? "#603F99" : "#cccccc")};
	border-radius: 10px;
	box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.05);
	transition: ease all 0.2s;
	outline: none;
	&:after {
		content: "";
		height: 24px;
		width: 24px;
		background-color: #ffffff;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
		border-radius: 12px;
		display: block;
		position: absolute;
		top: -2px;
		left: 0;
		box-sizing: border-box;
		transition: ease all 0.2s;
	}
`;

interface IToggle {
	isToggle?: boolean;
}
export const Switch = ({SwitchLabel, width, defaultChecked, name, onChange}: ISwitch) => (
	<StyledSwitch>
		<Label width={width}>{SwitchLabel}</Label>
		<SwitchOuter>
			<Checkbox type="checkbox" name={name} defaultChecked={defaultChecked} onChange={onChange} />
			<SwitchInner />
		</SwitchOuter>
	</StyledSwitch>
);
